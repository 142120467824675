import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export const Registers = ({ sessions }) => {
  const [sessionsData, setSessionsData] = useState([]);
  const [searchParam, setSearchParam] = useState("");

  useEffect(() => {
    if (sessions) {
      setSessionsData(sessions);
    }
  }, [sessions]);

  return (
    <>
      {sessionsData.length === 0 ? (
        <div className="flex justify-start">
          <h1 className="text-lg my-3 font-normal py-5 text-gray-900">
            You're Currently not assigned to any session. Contact admin to get
            assigned.
          </h1>
        </div>
      ) : (
        <div className="col-span-full">
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <h1 className="text-lg my-3 font-semibold text-gray-900">
                Your Class Sessions
              </h1>
            </div>
            <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
              <input
                type="search"
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                placeholder="Search sessions..."
                onChange={(e) => setSearchParam(e.target.value)}
              />
            </div>
          </div>
          {sessionsData.length !== 0 ? (
            <ul className="space-y-3">
              {sessionsData
                .filter((item) => {
                  console.log("Search", searchParam);
                  return searchParam.toLowerCase() === ""
                    ? item
                    : item.class.toLowerCase().includes(searchParam);
                })
                .map((session, index) => (
                  <li
                    key={index}
                    className="overflow-hidden bg-gray-100 border border-gray-300 px-6 py-2 shadow sm:rounded-md grid grid-cols-1 gap-4 content-start"
                  >
                    <div className="col-span-full py-1 flex justify-between ">
                      <h3 className="text-base font-bold">
                        Class: {session.class}
                      </h3>
                      <h3 className="text-base font-bold">
                        Day: {session.day}
                      </h3>

                      <Link
                        to="class-register"
                        state={{ sessionId: session.id }}
                        className="rounded-md bg-blue-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                      >
                        New Register
                      </Link>
                    </div>
                    {session.attendance.length !== 0 && (
                      <ul className="space-y-3">
                        {Object.entries(session.attendance).map(
                          ([date, data], index) => (
                            <li
                              key={index}
                              className="overflow-hidden bg-white px-6 py-2  shadow sm:rounded-md flex justify-between items-center"
                            >
                              <div>
                                <h4 className="block text font-medium text-gray-700 mb-1">
                                  {date} | {session.start_at} - {session.end_at}{" "}
                                </h4>
                                <p className="block text-sm font-medium text-gray-700">
                                  <span className="text-green-700">
                                    Present - {data.present_count}
                                    {/* {
                                      data.filter(
                                        (item) => item.attendance === "present"
                                      ).length
                                    } */}
                                  </span>
                                  ,{" "}
                                  <span className="text-red-700">
                                    Absent - {data.absent_count}
                                    {/* {
                                      data.filter(
                                        (item) => item.attendance === "absent"
                                      ).length
                                    } */}
                                  </span>
                                  ,{" "}
                                  <span className="text-blue-700">
                                    Unmarked - {data.unmarked_count}
                                    {/* {session.students.length -
                                      (data.filter(
                                        (item) => item.attendance === "absent"
                                      ).length +
                                        data.filter(
                                          (item) =>
                                            item.attendance === "present"
                                        ).length)} */}
                                  </span>
                                </p>
                              </div>
                              <div className="space-x-2">
                                  {data.status === "saved" ? (
                                    <span className="inline-flex items-center rounded-md bg-green-300 px-2 py-1 text-xs font-medium text-gray-700 ring-1 ring-inset ring-green-600/20">
                                      {data.status}
                                    </span>
                                  ) : (
                                    <span className="inline-flex items-center rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-800 ring-1 ring-inset ring-yellow-600/20">
                                      {data.status}
                                    </span>
                                  )}
                                {/* <Link
                                to="register"
                                className="text-sm p-2 sm:rounded-md  text-white bg-blue-600"
                              >
                                Save
                              </Link> */}
                                <Link
                                  to="class-register"
                                  state={{
                                    sessionId: session.id,
                                    date: date,
                                  }}
                                  className="text-sm p-2  sm:rounded-md border border-gray-300  text-blue-600"
                                >
                                  View
                                </Link>
                              </div>
                            </li>
                          )
                        )}
                      </ul>
                    )}
                  </li>
                ))}
            </ul>
          ) : (
            <h1>No data</h1>
          )}
        </div>
      )}
    </>
  );
};
