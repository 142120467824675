import { useDispatch, useSelector } from "react-redux";
import { getUserDetails } from "../../utils/helpers/authHelpers";
import {
  fetchTutors,
  selectTutorById,
  selectTutors,
  selectTutorsStatus,
} from "../../features/tutors/tutorSlice";
import { useEffect, useState } from "react";
import { Registers } from "./Registers";
import { Tutors } from "./Tutors";
import { Registrations } from "../dashboard/registrations";

export const TutorHome = () => {
  const [sessions, setSessions] = useState([]);
  const [tutorItem, setTutorItem] = useState();
  const [activeTab, setActiveTab] = useState("registers")

  const user = getUserDetails();
  const status = useSelector(selectTutorsStatus);
  const tutorId = user.id;


  const tutors = useSelector(selectTutors)
  const tutor = useSelector(state => selectTutorById(state, tutorId));
  const dispatch = useDispatch();

  // let today = new Date();

  // var day = today
  //   .toLocaleDateString(undefined, {
  //     weekday: "long",
  //     year: "numeric",
  //     month: "long",
  //     day: "numeric",
  //   })
  //   .split(",")[0];

  useEffect(() => {
    if (status === "idle") {
      dispatch(fetchTutors());
    }
    if(tutor){
      setTutorItem(tutor);
      setSessions(tutor.sessions);
    }
  }, [status, dispatch, user.id, activeTab, tutors, tutor]);
  return (
    <>
      <section className="justify-items-center flex justify-center overflow-y-auto px-4 pb-16 sm:pt-12 pt-0 sm:px-6 lg:px-8 lg:pb-24 lg:pt-0">
        <div className="w-3/4">
          <div className="mt-6 gap-x-4 gap-y-4">
            {/* <div className="col-span-full">
              <div className="p-4 bg-white border border-gray-200 rounded-xl shadow-sm">
                <h1 className="text-2xl font-bold">Cosmetology Class</h1>
              </div>
            </div> */}
            <div className="col-span-full my-4">
              <nav className="flex space-x-6" aria-label="Tabs">
                <button
                  className={
                    activeTab === "registers"
                      ? "bg-gray-200 rounded-md px-3 py-2 text-sm font-medium text-gray-600 hover:text-gray-800"
                      : "rounded-md px-3 py-2 text-sm font-medium text-gray-600 hover:text-gray-800"
                  }
                  onClick={() => setActiveTab("registers")}
                >
                  Mark Register
                </button>
                <button
                  className={
                    activeTab === "students"
                      ? "bg-gray-200 rounded-md px-3 py-2 text-sm font-medium text-gray-600 hover:text-gray-800"
                      : "rounded-md px-3 py-2 text-sm font-medium text-gray-600 hover:text-gray-800"
                  }
                  onClick={() => setActiveTab("students")}
                >
                  All Students
                </button>
                <button
                  className={
                    activeTab === "teachers"
                      ? "bg-gray-200 rounded-md px-3 py-2 text-sm font-medium text-gray-600 hover:text-gray-800"
                      : "rounded-md px-3 py-2 text-sm font-medium text-gray-600 hover:text-gray-800"
                  }
                  onClick={() => setActiveTab("teachers")}
                >
                  All Teachers
                </button>
              </nav>
            </div>
            {activeTab === "registers" && <Registers sessions={sessions} />}
            {activeTab === "teachers" && <Tutors />}
            {activeTab === "students" && <Registrations />}
          </div>
        </div>
      </section>
    </>
  );
};
