import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Apis from "../../utils/api/api";

export const fetchTutors = createAsyncThunk(
  "/registrations/fetchTutors",
  async () => {
    const { data: response } = await Apis.getTutors();
    // console.log("data", response.data);
    if (response.data) {
      return response.data;
    } else {
      return [];
    }
  }
);

export const fetchTutor = createAsyncThunk(
  "/tutors/fetchTutor",
  async (params) => {
    // console.log("params", params)
    const { data: response } = await Apis.getTutors(params);
    // console.log("Tutor response", response.data);
    if (response.data) {
      return response.data;
    } else {
      return {};
    }
  }
);

export const addTutorAsync = createAsyncThunk(
  "tutors/addTutor",
  async (tutor) => {
   try {
     const { status, data } = await Apis.addTutor(tutor);
     //   console.log("res success", response);
     if (status !== 201) {
       return { data: null, msg: data.msg, status: status };
     }

     return { data: data.data, msg: data.msg, status: 200 };
   } catch (error) {
    //  console.log("Error", error);
     const { data: response } = error.response;
     const { status } = error;
     return { data: null, msg: response.msg, status: status };
   }
  }
);

// export const selectTutorById = (state, tutorId) => {
//   return state.tutors.tutors.find((tutor) => tutor.id === tutorId);
// }

const initialState = {
  status: "idle",
  tutors: [],
  error: null,
  tutor: {},
};

const tutorSlice = createSlice({
  name: "tutors",
  initialState,
  reducers: {
    addTutor: (state, action) => {
      const tutors = state.tutors;
      const newTutors = [...tutors, action.payload];
      return newTutors;
    },
    getTutorById: (state, action) => {
      return state.tutors.find((tutor) => tutor.id === action.payload);
    },
  },
  selectors: {
    selectTutorsStatus: (state) => state.status,
    selectTutorsError: (state) => state.error,
    selectTutors: (state) => state.tutors,
    selectTutor: (state) => state.tutor,
    selectTutorById: (state, tutorId) =>
      state.tutors.find((tutor) => tutor.id === tutorId),
  },
  extraReducers(builder) {
    builder
      .addCase(addTutorAsync.fulfilled, (state, action) => {
        const { data, msg, status } = action.payload;
        state.error = msg;
        if (status !== 200) {
          state.status = "failed";
        } else {
          state.status = "success";
          state.tutors.push(data);
        }
      })
      .addCase(addTutorAsync.pending, (state, action) => {
        state.status = "pending";
        // state.tutors.push(action.payload);
      })
      .addCase(addTutorAsync.rejected, (state, action) => {
        state.status = "failed";
      })
      .addCase(fetchTutor.fulfilled, (state, action) => {
        state.status = "success";
        state.tutor = action.payload;
      })
      .addCase(fetchTutor.pending, (state, action) => {
        state.status = "pending";
      })
      .addCase(fetchTutor.rejected, (state, action) => {
        state.status = "failed";
      })
      .addCase(fetchTutors.fulfilled, (state, action) => {
        // console.log("action.payload", action.payload);
        state.status = "success";
        state.tutors = action.payload;
      })
      .addCase(fetchTutors.pending, (state, action) => {
        state.status = "pending";
      })
      .addCase(fetchTutors.rejected, (state, action) => {
        state.status = "failed";
      });
  },
});

export const { addTutor, getTutorById } = tutorSlice.actions;

export const {
  selectTutorsStatus,
  selectTutors,
  selectTutorById,
  selectTutorsError,
  selectTutor,
} = tutorSlice.selectors;

export default tutorSlice.reducer;
