import React from "react";
import { Home, Register, Enroll, Payment, Success, SearchResult, MpesaExpress, MpesaClassic, EditStudent } from "../pages/home";
import { Admin, Cashier, Cashiers, Courses, Enrollments, MpesaClassicRequests, PaymentRequests, Registrations, STKRequests, Student, Transactions, Tutor, Tutors } from "../pages/dashboard";
import { Login } from "../pages/auth";
import { AddCourse } from "../features/courses/AddCourseModal";
import Sessions from "../features/sessions/Sessions";
import Session from "../features/sessions/Session";
import { AddSessionForm } from "../features/sessions/AddSessionForm";
import { Course } from "../pages/dashboard/course";
import {
  AttendanceRegister,
  TutorHome,
} from "../pages/tutor";
import { ConfirmRegistration } from "../pages/home/confirmRegistration";
import { EditCourse } from "../features/courses/EditCourse";
import ClassList from "../features/classes/ClassList";
import ClassComponent from "../features/classes/Class";

// import Login from "../pages/auth/login";
// import Home from "../pages/home/home";
// import { AdminDashboard } from "../pages/dashboard";
// import Register from "../pages/home/register";
// import DefaultLayout from "../layout/DefaultLayout";
// import { Enroll } from "../pages/home/enroll";
// import { Result } from "../pages/home/searchResult";
// import Payment from "../pages/home/payment";
// import { Success } from "../pages/home/success";
// import { Student } from "../pages/dashboard/student";
// import CourseList from "../features/courses/CourseList";
// import { AdminRoutes } from "./admin";
// import { Tutors } from "../features/tutors/Tutors";


export const routes = [
  {
    title: "admin-dashboard",
    layout: "admin",
    pages: [
      {
        name: "dashboard",
        path: "/",
        element: <Admin />,
      },
      {
        name: "courses",
        path: "/courses",
        element: <Courses />,
      },
      {
        name: "courses-info",
        path: "/course-details",
        element: <Course />,
      },
      {
        name: "add-courses",
        path: "/add-course",
        element: <AddCourse />,
      },
      {
        name: "edit-courses",
        path: "/edit-course",
        element: <EditCourse />,
      },
      {
        name: "classes",
        path: "/classes",
        element: <ClassList />,
      },
      {
        name: "class-info",
        path: "/class-info",
        element: <ClassComponent />,
      },
      {
        name: "registrations",
        path: "/registrations",
        element: <Registrations />,
      },
      {
        name: "enrollments",
        path: "/enrollments",
        element: <Enrollments />,
      },
      {
        name: "tutors",
        path: "/tutors",
        element: <Tutors />,
      },
      {
        name: "cashier",
        path: "/cashiers",
        element: <Cashiers />,
      },
      // {
      //   name: "add-cashier",
      //   path: "/cashiers",
      //   element: <Cashiers />,
      // },
      {
        name: "cashier-details",
        path: "/cashier-details",
        element: <Cashier />,
      },
      {
        name: "tutor-details",
        path: "/tutor-details",
        element: <Tutor />,
      },
      {
        name: "class-sessions",
        path: "/sessions",
        element: <Sessions />,
      },
      {
        name: "add-session",
        path: "/add-session",
        element: <AddSessionForm />,
      },
      {
        name: "class-session-info",
        path: "/class-session-info",
        element: <Session />,
      },
      {
        name: "payment-requests",
        path: "/payment-requests",
        element: <PaymentRequests />,
      },
      {
        name: "transactions",
        path: "/transactions",
        element: <Transactions />,
      },
      {
        name: "mpesa-express-requests",
        path: "/mpesa-express-requests",
        element: <STKRequests />,
      },
      {
        name: "mpesa-classic-requests",
        path: "/mpesa-classic-requests",
        element: <MpesaClassicRequests />,
      },
      {
        name: "mpesa-classic-requests",
        path: "/mpesa-classic-requests",
        element: <Transactions />,
      },
    ],
  },
  {
    title: "tutor-dashboard",
    layout: "tutor",
    pages: [
      {
        name: "home",
        path: "/",
        element: <TutorHome />,
      },
      {
        name: "class-register",
        path: "/class-register",
        element: <AttendanceRegister />,
      },
      // {
      //   name: "new-register",
      //   path: "/new-register",
      //   element: <NewClassRegister />,
      // },
    ],
  },
  {
    title: "finance-dashboard",
    layout: "finance",
    pages: [
      {
        name: "courses",
        path: "/registrations",
        element: <Registrations />,
      },
      {
        name: "tutors",
        path: "/tutors",
        element: <Tutors />,
      },
      {
        name: "payment-requests",
        path: "/payment-requests",
        element: <PaymentRequests />,
      },
      {
        name: "transactions",
        path: "/transactions",
        element: <Transactions />,
      },
      {
        name: "mpesa-express-requests",
        path: "/mpesa-express-requests",
        element: <STKRequests />,
      },
      {
        name: "mpesa-classic-requests",
        path: "/mpesa-classic-requests",
        element: <MpesaClassicRequests />,
      },
    ],
  },
  {
    title: "authpages",
    layout: "auth",
    pages: [
      {
        name: "login",
        path: "/login",
        element: <Login />,
      },
    ],
  },

  {
    title: "student pages",
    layout: "student",
    pages: [
      {
        name: "home",
        path: "/",
        element: <Home />,
      },
      {
        name: "student-details",
        path: "/student-details",
        element: <Student />,
      },
      {
        name: "update-student",
        path: "/update-student",
        element: <EditStudent />,
      },
      {
        name: "register",
        path: "/register",
        element: <Register />,
      },
      {
        name: "register-confirmation",
        path: "/registration-confirmation",
        element: <ConfirmRegistration />,
      },
      {
        name: "enroll",
        path: "/enroll",
        element: <Enroll />,
      },
      {
        name: "search-results",
        path: "/search-results",
        element: <SearchResult />,
      },
      {
        name: "mpesa-classic",
        path: "/mpesa-classic",
        element: <MpesaClassic />,
      },
      {
        name: "mpesa-express",
        path: "/mpesa-express",
        element: <MpesaExpress />,
      },
      {
        name: "enrollment-success",
        path: "/enrollment-successful",
        element: <Success />,
      },
      {
        name: "payment",
        path: "/pay",
        element: <Payment />,
      },
    ],
  },
];

