import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addTutorAsync,
  selectTutorsError,
  selectTutorsStatus,
} from "./tutorSlice";
import Alert from "../../components/common/alert";

const Modal = ({ show, handleClose }) => {
  const [showModal, setShowModal] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [tutor, setTutor] = useState({});
  const [showAlert, setShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");

  const dispatch = useDispatch();
  const status = useSelector(selectTutorsStatus);
  const err = useSelector(selectTutorsError);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setTutor({ ...tutor, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await dispatch(addTutorAsync(tutor)).unwrap();
    } catch (error) {
      // console.log(error);
      const { data: response } = error;
      setAlertMsg({
        ...alertMsg,
        msg: response.msg,
      });
      setShowAlert(true);
    } finally {
      setAlertMsg({
        ...alertMsg,
        msg: err,
      });
      setShowAlert(true);
      e.target.reset();
      setTutor({});
      setTimeout(() => {
        handleClose();
      }, 3000);
    }
  };
  useEffect(() => {
    //
    setTimeout(() => {
      setShowAlert(false);
    }, 3000);
  }, [showAlert]);
  return (
    <>
      {show && (
        <div
          className="lg:pl-72 relative z-10"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div
            className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
            aria-hidden="true"
          ></div>

          <div className="fixed inset-0 z-1 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <div className="relative size-3/5 transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all ">
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                      <h1 className="text-lg font-semibold text-gray-900">
                        Add Tutor
                      </h1>
                    </div>
                    <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                      <button
                        type="button"
                        className="mt-2 justify-center py-2 text-sm font-semibold text-white ring-gray-300 sm:mt-0 sm:w-auto"
                        onClick={handleClose}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          className="size-6 rounded-md bg-red-500 hover:bg-red-400"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                  <div>
                    <hr className="h-px my-3 bg-gray-200 border-0 dark:bg-gray-700"></hr>
                  </div>
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto max-w-full">
                      {showLoading ? (
                        <div className="mx-auto max-w-lg">
                          <div className="relative items-center block max-w-sm p-6 bg-white border border-gray-100 rounded-lg shadow-md dark:bg-gray-800 dark:border-gray-800 dark:hover:bg-gray-700">
                            <h5 className="mb-2 text-center text-2xl font-bold tracking-tight text-gray-400 dark:text-white opacity-20">
                              Add Tutor
                            </h5>
                            <div
                              role="status"
                              className="absolute -translate-x-1/2 -translate-y-1/2 top-2/4 left-1/2"
                            >
                              <svg
                                aria-hidden="true"
                                className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                                viewBox="0 0 100 101"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                  fill="currentColor"
                                />
                                <path
                                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                  fill="currentFill"
                                />
                              </svg>
                              <span className="sr-only">
                                Processing your request ...
                              </span>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <form className="mt-6" onSubmit={handleSubmit}>
                          <div className="grid grid-cols-12 gap-x-4 gap-y-4">
                            <div className="col-span-full">
                              {showAlert && (
                                <Alert
                                  show={showAlert}
                                  content={err}
                                  type={status}
                                ></Alert>
                              )}
                            </div>

                            <div className="col-span-full">
                              <div className="p-1">
                                <label
                                  htmlFor="first-name"
                                  className="block text font-medium text-gray-700"
                                >
                                  First Name
                                </label>
                                <div className="mt-1">
                                  <input
                                    type="text"
                                    name="first_name"
                                    id="first-name"
                                    autoComplete="First Name"
                                    placeholder="First Name"
                                    onChange={handleInputChange}
                                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                  ></input>
                                </div>
                              </div>
                            </div>
                            <div className="col-span-full">
                              <div className="p-1">
                                <label
                                  htmlFor="last-name"
                                  className="block text font-medium text-gray-700"
                                >
                                  Last Name
                                </label>
                                <div className="mt-1">
                                  <input
                                    type="text"
                                    name="last_name"
                                    id="last-name"
                                    autoComplete="Last Name"
                                    placeholder="Last Name"
                                    onChange={handleInputChange}
                                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                  ></input>
                                </div>
                              </div>
                            </div>
                            <div className="col-span-full">
                              <div className="p-1">
                                <label
                                  htmlFor="msisdn"
                                  className="block text font-medium text-gray-700"
                                >
                                  Phone Number
                                </label>
                                <div className="mt-1">
                                  <input
                                    type="text"
                                    name="msisdn"
                                    id="msisdn"
                                    autoComplete="Phone Number"
                                    placeholder="Phone Number"
                                    onChange={handleInputChange}
                                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                  ></input>
                                </div>
                              </div>
                            </div>
                            <div className="col-span-full">
                              <div className="p-1">
                                <label
                                  htmlFor="email-address"
                                  className="block text font-medium text-gray-700"
                                >
                                  Email
                                </label>
                                <div className="mt-1">
                                  <input
                                    type="email"
                                    name="email"
                                    id="email-address"
                                    autoComplete="Email Address"
                                    placeholder="Email Address"
                                    onChange={handleInputChange}
                                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                  ></input>
                                </div>
                              </div>
                            </div>
                            <div className="col-span-full">
                              <div className="p-4 mt-3 bg-white border border-gray-200 rounded-xl shadow-sm">
                                <label
                                  htmlFor="new-password"
                                  className="block text-sm font-medium leading-6 text-gray-700"
                                >
                                  Password
                                </label>
                                <input
                                  id="password"
                                  name="password"
                                  type="password"
                                  placeholder="Password"
                                  onChange={handleInputChange}
                                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                />
                              </div>
                            </div>
                            <div className="flex justify-between">
                              <button
                                type="submit"
                                className="mt-6 rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:bg-gray-500"
                              >
                                Submit
                              </button>
                            </div>
                          </div>
                        </form>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Modal;
