import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Apis from "../../utils/api/api";

export const fetchClasses = createAsyncThunk(
  "/classes/fetchClasses",
  async (class_id) => {
    const { data: response } = await Apis.getClasses(class_id);
    console.log("data", response.data);
    if (response.data) {
      return response.data;
    } else {
      return [];
    }
  }
);

export const fetchClass = createAsyncThunk(
  "/classes/fetchClass",
  async (param) => {
    const { data: response } = await Apis.getClass(param);
    // console.log(response.data);
    if (response.data) {
      return response.data;
    } else {
      return [];
    }
  }
);

export const addClass = createAsyncThunk(
  "classes/addClass",
  async (class_) => {
    try {
      const { data: response } = await Apis.addClass(class_);
      // console.log("res success", response);
      if (!response.success) {
        return null;
      }
      return response.data;
    } catch (error) {
      console.log("Error:", error);
      return null;
    }
  }
);

const initialState = {
  status: "idle",
  classList: [],
  error: null,
  class_: {},
};

const classSlice = createSlice({
  name: "classes",
  initialState,
  reducers: {
    addClass: (state, action) => {
      const classes = state.classes;
      const newClasses = [...classes, action.payload];
      return newClasses;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(addClass.fulfilled, (state, action) => {
        state.status = "success";
        state.classes.push(action.payload);
      })
      .addCase(addClass.pending, (state, action) => {
        state.status = "pending";
        state.classes.push(action.payload);
      })
      .addCase(addClass.rejected, (state, action) => {
        state.status = "failed";
      })
      .addCase(fetchClass.fulfilled, (state, action) => {
        state.status = "success";
        state.class_ = action.payload;
      })
      .addCase(fetchClass.pending, (state, action) => {
        state.status = "pending";
      })
      .addCase(fetchClass.rejected, (state, action) => {
        state.status = "failed";
      })
      .addCase(fetchClasses.fulfilled, (state, action) => {
        state.status = "success";
        state.classes = action.payload;
      })
      .addCase(fetchClasses.pending, (state, action) => {
        state.status = "pending";
      })
      .addCase(fetchClasses.rejected, (state, action) => {
        state.status = "failed";
      });
  },
});

export const selectClasses = (state) => state.classes.classList;
export const selectClass = (state) => state.classes.class_;
export default classSlice.reducer;
