import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Apis from "../../utils/api/api";

export const updatePassword = createAsyncThunk(
  "auth/updatePassword",
  async (data, { rejectWithValue }) => {
    try {
      const {
        data: { msg, success, user },
      } = await Apis.changePassword(data);
      //   console.log("res success", response);
      if (!success) {
        return { msg: msg, status: 400 };
      }
      return { user, msg };
    } catch (error) {
      const { data: response } = error.response;
      const { status } = error;
      // console.log("Error:", status);
      return rejectWithValue({ msg: response.msg, status });
    }
  }
);

const initialState = {
  loggedIn: false,
  state: "idle",
  authType: "",
  error: "",
  user: {}
};
export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setLogin: (state, action) => {
      switch (action.payload) {
        case true:
          return { ...state, loggedIn: true };
        case false:
          return { ...state, loggedIn: false };
        default:
          return state;
      }
    },
    setAuthType: (state, action) => {
      return { ...state, authType: action.payload };
    },
  },
  extraReducers(builder){
    builder
    .addCase(updatePassword.fulfilled, (state, action) => {
        const { user, msg } = action.payload;
        state.user = user;
        state.state = "success";
        state.error = msg;
      })
      .addMatcher(
        (action) => action.type.endsWith("/pending"),
        (state) => {
          state.state = "pending";
        }
      )
      .addMatcher(
        (action) => action.type.endsWith("/rejected"),
        (state, action) => {
          state.state = "failed";
          state.error = action.payload.msg;
        } 
      );
  }
});

export const selectAuth = (state) => state.auth.loggedIn;
export const selectAuthType = (state) => state.auth.authType;
export const selectAuthState = (state) => state.auth.state;
export const selectAuthError = (state) => state.auth.error
export const selectUser = (state) => state.auth.user

export default authSlice.reducer;

export const { setLogin, setAuthType } = authSlice.actions;
