import { useDispatch, useSelector } from "react-redux";
import {
  getStudent,
  selectStudentById,
} from "../../features/registrations/registrationSlice";
import { useCallback, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import UpdateEnrollment from "../home/UpdateEnrollment";
import { RemoveEnrollmentModal } from "../home/RemoveEnrollmentModal";


export const Student = () => {
  const [showLoading, setShowLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [enrollment, setEnrollment] = useState("");
  const [student, setStudent] = useState({});
  // const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { id } = location.state;
  console.log("id", id)
  const studentObject = useSelector((state) => selectStudentById(state, id));
  console.log("id", studentObject);

  const handleClick = (enrollment) => {
    // console.log("Clie")
    setEnrollment(enrollment);
    setShowModal(true);
  };

  const handleDelete = (enrollment) => {
    setEnrollment(enrollment);
    setShowRemoveModal(true);
  };



  const fetchStudent = useCallback(async () => {
    await dispatch(getStudent({ params: { q: id } }))
      .unwrap()
      .then((results) => {
        if (results !== null) {
          setShowLoading(false);
          setStudent(results);
        }
      });
  }, [dispatch, id]);

  useEffect(() => {
    if (!studentObject) {
      fetchStudent();
    } else {
      setShowLoading(false);
      setStudent(studentObject);
    }
  }, [fetchStudent, studentObject]);
  return (
    <>
      {showModal && (
        <UpdateEnrollment
          show={showModal}
          handleClose={() => setShowModal(false)}
          enrollment={enrollment}
        />
      )}

      {showRemoveModal && (
        <RemoveEnrollmentModal
          show={showRemoveModal}
          handleClose={() => setShowRemoveModal(false)}
          enrollment={enrollment}
        />
      )}
      <div className="flex flex-col items-center justify-center mx-auto max-w-2xl lg:max-w-none">
        <div className="flex items-center justify-center mx-auto mb-8 mt-8 lg:mt-8">
          <a href="/">
            <span className="sr-only">IYF Free Weekend Academy</span>
            <img
              src="../assets/png/iyflogo-s7.png"
              alt=""
              className="h-12 lg:h-16 w-auto"
            ></img>
          </a>
        </div>
      </div>
      {!student && showLoading ? (
        <div className="mx-auto max-w-lg">
          <div className="relative items-center block max-w-sm p-6 bg-white border border-gray-100 rounded-lg shadow-md dark:bg-gray-800 dark:border-gray-800 dark:hover:bg-gray-700">
            <h5 className="mb-2 text-center text-2xl font-bold tracking-tight text-gray-400 dark:text-white opacity-20">
              Loading Student Details
            </h5>
            <div
              role="status"
              className="absolute -translate-x-1/2 -translate-y-1/2 top-2/4 left-1/2"
            >
              <svg
                aria-hidden="true"
                className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
              <span className="sr-only">Loading student ...</span>
            </div>
          </div>
        </div>
      ) : (
        <div className="px-4 sm:px-6 lg:px-8">
          <h1 className="sr-only">IYF Free Academy - Registration</h1>
          <div className="card bg-white  mx-auto max-w-7xl lg:flex lg:gap-x-16 lg:px-8">
            <main className="px-4 sm:px-6 lg:flex-auto lg:px-0 lg:py-12 pb-4 pt-4">
              <div className="px-5 border border-gray-200 rounded-xl">
                <div className="py-4 flex flex-col sm:flex-row justify-between items-center">
                  <h1 className="text-2xl font-semibold text-gray-900">
                    Student Details
                  </h1>
                </div>
                <div className="flex flex-col sm:flex-row justify-between items-center">
                  <h1 className="text-2xl font-semibold text-gray-900">
                    {student.full_name}
                  </h1>
                  <Link
                    to="/update-student"
                    state={{ id: student.id }}
                    className="rounded-md border text-sm font-medium text-blue-600 px-4 py-2 hover:bg-white"
                  >
                    Edit Details
                  </Link>
                </div>
                <div className="mx-auto max-w-2xl space-y-16 sm:space-y-20 lg:mx-0 lg:max-w-none mt-4">
                  <dl className="mt-2 divide-y divide-gray-200 border-t border-gray-200">
                    <div className="flex justify-between py-3 text-sm font-medium">
                      <dt className="text-gray-500">Date of Birth</dt>
                      <dd className="text-gray-900">{student.dob}</dd>
                    </div>
                    <div className="flex justify-between py-3 text-sm font-medium">
                      <dt className="text-gray-500">ID Number</dt>
                      <dd className="text-gray-900">{student.id_number}</dd>
                    </div>
                    <div className="flex justify-between py-3 text-sm font-medium">
                      <dt className="text-gray-500">Phone Number</dt>
                      <dd className="text-gray-900">{student.msisdn}</dd>
                    </div>
                    <div className="flex justify-between py-3 text-sm font-medium">
                      <dt className="text-gray-500">Email</dt>
                      <dd className="text-gray-900">{student.email}</dd>
                    </div>
                    <div className="flex justify-between py-3 text-sm font-medium">
                      <dt className="text-gray-500">Name of Parent</dt>
                      <dd className="text-gray-900">{student.guardian_name}</dd>
                    </div>
                    <div className="flex justify-between py-3 text-sm font-medium">
                      <dt className="text-gray-500">
                        ID No. of Parent or Guardian
                      </dt>
                      <dd className="text-gray-900">
                        {student.guardian_id_no}
                      </dd>
                    </div>
                    <div className="flex justify-between py-3 text-sm font-medium">
                      <dt className="text-gray-500">Current Residence</dt>
                      <dd className="text-gray-900">{student.residence}</dd>
                    </div>

                    <div className="flex justify-between py-3 text-sm font-medium">
                      <dt className="text-gray-500">
                        How did you know about weekend academy?
                      </dt>
                      <dd className="text-gray-900">{student.channel}</dd>
                    </div>
                    <div className="flex justify-between py-3 text-sm font-medium">
                      <dt className="text-gray-500">
                        New or Continuing student?
                      </dt>
                      <dd className="text-gray-900">
                        {student.enrollment_status}
                      </dd>
                    </div>
                  </dl>
                </div>
              </div>

              <div className="px-5 mt-5 border border-gray-200 rounded-xl">
                <div className="py-2 flex flex-col sm:flex-row justify-between items-center border-b">
                  <h1 className="text-2xl font-semibold text-gray-900">
                    Enrollment Info
                  </h1>
                </div>
                {student.enrollments?.length !== 0 ? (
                  student.enrollments?.map((enrollment, index) => (
                    <div
                      key={index}
                      className="flex flex-col sm:flex-row justify-between items-center p-2 my-3 border border-gray-200 rounded-xl"
                    >
                      <div className="flex flex-col sm:flex-row justify-between items-center">
                        <h1 className="text-base font-semibold text-gray-900">
                          Course: {enrollment.class.course}
                        </h1>
                        {/* <a
                          href="student_edit_details.html"
                          type="submit"
                          className="rounded-md text-sm font-medium text-blue-600 px-4 py-2 hover:bg-white"
                        >
                          Edit Details
                        </a> */}
                      </div>
                      <div className="flex flex-col m-3 sm:flex-row justify-between items-center">
                        <h3 className="text-sm font-medium text-gray-900">
                          Class: {enrollment.class.class}
                        </h3>
                      </div>
                      <div className="flex flex-col sm:flex-row justify-between items-center">
                        <button
                          // href="course_edit_details.html"
                          type="submit"
                          className="rounded-md border border-gray-200 text-sm font-medium text-blue-600 px-4 py-2 hover:bg-white"
                          onClick={() => handleClick(enrollment)}
                        >
                          Edit Course
                        </button>
                      </div>
                      <div className="flex flex-col sm:flex-row justify-between items-center">
                        <button
                          // href="course_edit_details.html"
                          type="submit"
                          className="rounded-md border border-gray-200 text-sm font-medium text-red-700 px-4 py-2 hover:bg-white"
                          onClick={() => handleDelete(enrollment)}
                        >
                          Remove Enrollment
                        </button>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="py-4 flex flex-col sm:flex-row justify-between items-center">
                    <h1 className="text-base text-gray-900">
                      No course enrolled
                    </h1>
                    <Link
                      to="/enroll"
                      className="mt-6 rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                    >
                      Enroll
                    </Link>
                  </div>
                )}
              </div>
            </main>
          </div>
        </div>
      )}
    </>
  );
};
