import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectUser, updatePassword } from "../features/auth/authSlice";
import Alert from "../components/common/alert";
import { useNavigate } from "react-router-dom";
import { getUserDetails, setUserDetails } from "../utils/helpers/authHelpers";

const PasswordUpdateModal = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userDetails = getUserDetails();
  // console.log("User", userDetails)

  const [updates, setUpdates] = useState({
    new_assword: "",
    confirm_password: "",
  });
  const [alert, setAlert] = useState({ show: false, msg: "", type: "" });
  
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUpdates({ ...updates, [name]: value });
  };

  const handleConfirmPassword = (e) => {
    const { name, value } = e.target;
    if (value !== updates.new_password) {
      setAlert((prev) => ({
        ...prev,
        show: true,
        msg: "Password entered do not match. Please ensure you enter the same password",
        type: "failed",
      }));
    }
    setUpdates({ ...updates, [name]: value, user_type: userDetails.user_type });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await dispatch(updatePassword(updates)).unwrap()
      .then(({ user, msg }) => {
        // console.log("User", user, msg);
        setUserDetails(user);
        setAlert({
          ...alert,
          show: true,
          msg: `${msg}. Redirecting ...`,
          type: "success",
        });
        setTimeout(() => {
          navigate(0);
        }, 2000);
      });
    } catch (error) {
      // console.log("Erro", error);
      const { msg } = error;
      setAlert({
        ...alert,
        show: true,
        msg: msg,
        type: "failed",
      });
    } finally {
      e.target.reset();
      setUpdates({});
    }
  };
  
  useEffect(() => {
    console.log("fired");
    setTimeout(() => {
      if(alert.show) setAlert({...alert,show: false});
    }, 5000);
  }, [alert]);

  return (
    <>
        <div
          className="relative z-10"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div
            className="fixed inset-0 bg-gray-500 bg-opacity-15 transition-opacity"
            aria-hidden="true"
          ></div>

          <div className="fixed inset-0 z-1 w-screen overflow-y-auto">
            <div className="flex min-h-0 items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <div className="relative size-1/4 transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all ">
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                      <h1 className="text-lg font-semibold text-gray-900">
                        First Time Login Password Update
                      </h1>
                    </div>
                  </div>
                  <hr className="h-px my-3 bg-gray-200 border-0 dark:bg-gray-700"></hr>
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto max-w-full">
                      <div className="col-span-full bg-green-300 rounded-lg py-2">
                        <h1 className="text-xl mx-3 font-medium text-gray-900">
                          Congratulations, Login successful!
                        </h1>
                        <h1 className="text-lg mx-3 mt-4 font-normal text-gray-900">
                          To proceed, please change your password to one that is
                          secure and easy to remember for you. <br />
                        </h1>
                        <span className="text-lg p-1.5 mx-3 m-5 font-normal text-gray-900 bg-rose-300 rounded-md">
                          Warning: Please do not share this with anyone.
                        </span>
                      </div>
                      <form className="mt-6" onSubmit={handleSubmit}>
                        <div className="grid grid-cols-12 gap-x-4 gap-y-4">
                          <div className="col-span-full">
                            {alert.show && (
                              <Alert
                                show={alert.show}
                                content={alert.msg}
                                type={alert.type}
                              ></Alert>
                            )}
                          </div>
                          {/* <input type="hidden" name="id" value={user.user_type} /> */}
                          <div className="col-span-full">
                            <div className="p-4 mt-3 bg-white border border-gray-200 rounded-xl shadow-sm">
                              <label
                                htmlFor="new-password"
                                className="block text-sm font-medium leading-6 text-gray-700"
                              >
                                New Password
                              </label>
                              <input
                                id="new-password"
                                name="new_password"
                                type="password"
                                placeholder="Confirm New Password"
                                // value={updates.newPassword}
                                onChange={handleInputChange}
                                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                              />
                            </div>
                          </div>
                          <div className="col-span-full">
                            <div className="p-4 mt-3 bg-white border border-gray-200 rounded-xl shadow-sm">
                              <label
                                htmlFor="confirm-password"
                                className="block text-sm font-medium leading-6 text-gray-700"
                              >
                                Confirm New Password
                              </label>
                              <input
                                id="confirm-password"
                                name="confirm_password"
                                type="password"
                                placeholder="Confirm New Password"
                                // value={updates.confirmPassword}
                                onChange={handleConfirmPassword}
                                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                              />
                            </div>
                          </div>

                          <div className="flex justify-between">
                            <button
                              type="submit"
                              className="mt-6 rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:bg-gray-500"
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </>
  );
};

export default PasswordUpdateModal;
