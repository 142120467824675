import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Apis from "../../utils/api/api";

export const fetchCashiers = createAsyncThunk(
  "/registrations/fetchCashiers",
  async (params, { rejectWithValue }) => {
    console.log("params", params);
    try {
      const {
        data: { data, msg },
      } = await Apis.getCashiers(params);
      // console.log("data", response.data);
      return { data, msg };
    } catch (error) {
      const {
        data: { msg },
      } = error.response;
      // console.log("Error:", status);
      return rejectWithValue(
        msg || "Fetching data failed. Please try again later."
      );
    }
  }
);

export const addCashierAsync = createAsyncThunk(
  "cashiers/addCashier",
  async (cashier, { rejectWithValue }) => {
    try {
      const { data: response } = await Apis.addCashier(cashier);
      return response;
    } catch (error) {
      console.log("Error", error);
      const {
        data: { msg },
      } = error.response;
      return rejectWithValue(msg || "Request failed. Try again later.");
    }
  }
);

export const updateCashierThunk = createAsyncThunk(
  "cashiers/updateCashier",
  async (updateData, { rejectWithValue }) => {
    try {
      const { data: response } = await Apis.updateCashier(updateData);

      return response;
    } catch (error) {
      console.log("Error", error.response);
      const {
        data: { msg }
      } = error.response;
      return rejectWithValue(
        msg || "Updating cashier failed. Try again later."
      );
    }
  }
);

// export const selectCashierById = (state, cashierId) => {
//   return state.cashiers.cashiers.find((cashier) => cashier.id === cashierId);
// }

const initialState = {
  status: "idle",
  cashiers: [],
  error: null,
  cashierObject: {},
};

const cashierSlice = createSlice({
  name: "cashiers",
  initialState,
  reducers: {
    addCashier: (state, action) => {
      const cashiers = state.cashiers;
      const newCashiers = [...cashiers, action.payload];
      return newCashiers;
    },
    getCashierById: (state, action) => {
      return state.cashiers.find((cashier) => cashier.id === action.payload);
    },
  },
  selectors: {
    selectCashierStatus: (state) => state.status,
    selectCashiersError: (state) => state.error,
    selectCashiers: (state) => state.cashiers,
    selectCashierById: (state, id) =>
      state.cashiers.find((cashier) => cashier.id === id),
  },
  extraReducers(builder) {
    builder
      .addCase(addCashierAsync.fulfilled, (state, action) => {
        const { data, msg, status } = action.payload;
        state.error = msg;
        if (status !== 200) {
          state.status = "failed";
        } else {
          state.status = "success";
          state.cashiers.push(data);
        }
      })
      .addCase(updateCashierThunk.fulfilled, (state, action) => {
        const { data, msg } = action.payload;
        state.status = "success";
        state.error = msg
        const index = state.cashiers.findIndex((item) => item.id === data.id);
        state.cashiers[index] = data;
      })
      .addCase(fetchCashiers.fulfilled, (state, action) => {
        const { data, msg } = action.payload;
        state.error = msg;
        state.status = "success";
        if (Array.isArray(data)) {
          state.cashiers = data;
        } else {
          state.cashierObject = data;
        }
      })
      .addMatcher(
        (action) => action.type.endsWith("/pending"),
        (state) => {
          state.status = "pending";
        }
      )
      .addMatcher(
        (action) => action.type.endsWith("/rejected"),
        (state, action) => {
          state.status = "failed";
          state.error = action.payload;
        }
      );
  },
});

export const { addCashier, getCashierById } = cashierSlice.actions;

export const {
  selectCashierStatus,
  selectCashiersError,
  selectCashiers,
  selectCashierById,
} = cashierSlice.selectors;

export default cashierSlice.reducer;
