import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { selectGetResults } from "../../features/registrations/registrationSlice";
import { useNavigate } from "react-router-dom";
import {
  selectEnrollment,
  setEnrollData,
} from "../../features/enrollment/enrollSlice";
import {
  selectCourses,
  fetchCourses,
} from "../../features/courses/courseSlice";

import {
  selectSessions,
  fetchSessions,
} from "../../features/sessions/sessionSlice";

export const SearchResult = () => {

  const [enrollInfo, setEnrollInfo] = useState({});
  const [courseId, setCourseId] = useState(0);
  // const [courses, setCourses] = useState([]);
  // const [sessions, setSessions] = useState([]);
  const studentObject = useSelector(selectGetResults);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const courses = useSelector(selectCourses);
  const courseStatus = useSelector((state) => state.courses.status);

  const sessions = useSelector(selectSessions);
  const sessionStatus = useSelector((state) => state.sessions.status);

  const enrollmentData = useSelector(selectEnrollment);

  // console.log(courses, courseStatus)

  const handleCourseInputChange = (e) => {
    setCourseId(e.target.value);
    console.log("courseId", courseId);
    if (courseId !== 0) {
      console.log("courseId", courseId);
      dispatch(fetchSessions(parseInt(courseId)));
      setEnrollInfo({
        ...enrollInfo,
        course_id: parseInt(courseId),
        student_id: parseInt(studentObject.id),
      });
      console.log("StInfo", enrollInfo);
    }
    // await dispatch(fetchSessions(courseId));
    updateEnrollment(enrollInfo);
    console.log("sessions", sessions);
  };

  const handleInputChange = (e) => {
    e.preventDefault();
    // const { name, value } = e.target;
    // setEnrollInfo({ ...enrollInfo, [name]: value, student_id: studentObject.id});
  };

  const updateEnrollment = async (data) => {
    console.log("EnrollUpdated", enrollmentData);
    await dispatch(setEnrollData(data));
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    updateEnrollment(enrollInfo).then(navigate("/payment"));
  };

  //  useEffect(() => {
  //    if (Object.keys(resultObject).length !== 0) {
  //      console.log("stdnt", resultObject);
  //      navigate("/search-results");
  //    } else {
  //      // setShowError(true);
  //    }
  //    setTimeout(() => {
  //      setShowError(false);
  //    }, 5000);
  //  }, [resultObject, dispatch, navigate]);

  useEffect(() => {
    if (courseStatus === "idle") {
      dispatch(fetchCourses());
    }

    // if (Object.keys(studentObject).length !== 0) {
    //   setEnrollInfo({enrollInfo, student_id: studentObject.id});
    // }
  }, [
    courses,
    sessionStatus,
    sessions,
    courseStatus,
    dispatch,
    studentObject,
    courseId,
    enrollmentData,
  ]);

  return (
    <>
      <div className="px-4 py-6 sm:px-6 lg:hidden">
        <div className="mx-auto flex max-w-lg">
          <a href="/">
            <span className="sr-only">IYF Free Weekend Academy</span>
            <img
              src="../assets/png/iyflogo-s7.png"
              alt=""
              className="h-12 w-auto"
            ></img>
          </a>
        </div>
      </div>
      <h1 className="sr-only">IYF Free Academy - Registration</h1>
      {/* <!-- Checkout form --> */}
      {Object.keys(studentObject).length !== 0 ? (
        <section
          aria-labelledby="payment-heading"
          className="flex-auto overflow-y-auto px-4 pb-16 sm:pt-12 pt-0 sm:px-6 lg:px-8 lg:pb-24 lg:pt-0"
        >
          <div className="mx-auto max-w-lg">
            <div className="hidden pb-4 pt-12 lg:flex">
              <a href="/">
                <span className="sr-only">IYF Free Academy</span>
                <img
                  src="../assets/png/iyflogo-s7.png"
                  alt=""
                  className="h-20 w-auto"
                ></img>
              </a>
            </div>

            <div className="grid grid-cols-12 gap-x-4 gap-y-4">
              <div className="col-span-full">
                <div className="p-4 bg-white border border-gray-200 rounded-xl shadow-sm">
                  <h1 className="text-2xl font-bold">
                    {studentObject.full_name}
                  </h1>
                  <p>Phone No: {studentObject.msisdn}</p>
                  {studentObject.email && <p>Email: {studentObject.email}</p>}
                  <p>ID No: {studentObject.id_number}</p>
                  <p>Current Residence: {studentObject.residence}</p>
                </div>
              </div>
            </div>
            <form className="mt-6" onSubmit={handleSubmit}>
              <input
                type="hidden"
                name="student_id"
                id="student-id"
                autoComplete="StudentId"
                value={studentObject.id}
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                hidden
              ></input>
              <div className="grid grid-cols-12 gap-x-4 gap-y-4">
                <div className="col-span-full">
                  <div className="p-4 bg-white border border-gray-200 rounded-xl shadow-sm">
                    <label
                      htmlFor="course"
                      className="block text font-medium text-gray-700"
                    >
                      Select Course
                    </label>
                    <div className="mt-1">
                      <select
                        id="course"
                        name="course_id"
                        defaultValue="Select Course"
                        onChange={handleCourseInputChange}
                        className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-700 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
                      >
                        <option>Select Course</option>
                        {courses.map((course, index) => (
                          <option key={index} value={course.id}>
                            {course.course_name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>

                <div className="col-span-full">
                  <div className="p-4 bg-white border border-gray-200 rounded-xl shadow-sm">
                    <label
                      htmlFor="session"
                      className="block text font-medium text-gray-700"
                    >
                      Select Class Session
                    </label>
                    <div className="mt-1">
                      <select
                        id="session"
                        name="session_id"
                        placeholder="Select Class Session"
                        onChange={handleInputChange}
                        className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-700 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
                      >
                        {sessions.map((session, index) => (
                          <option key={index} value={session.id}>
                            Day-{session.day}, From: {session.start_at} To:
                            {session.end_at}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex justify-between">
                <Link
                  to="/payment"
                  className="mt-6 rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                >
                  Go to payment
                </Link>
                <Link
                  to="/enroll"
                  className="mt-6 rounded-md text-sm text-blue-600 px-4 py-2 hover:bg-white"
                >
                  Back
                </Link>
              </div>

              <p className="mt-6 flex justify-center text-sm font-medium text-gray-500">
                <svg
                  className="mr-1.5 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 1a4.5 4.5 0 00-4.5 4.5V9H5a2 2 0 00-2 2v6a2 2 0 002 2h10a2 2 0 002-2v-6a2 2 0 00-2-2h-.5V5.5A4.5 4.5 0 0010 1zm3 8V5.5a3 3 0 10-6 0V9h6z"
                    clipRule="evenodd"
                  />
                </svg>
                We adhere to the highest standards of data security in all
                processing and storage of your information.
              </p>
            </form>
          </div>
        </section>
      ) : (
        <section
          aria-labelledby="payment-heading"
          className="flex-auto overflow-y-auto px-4 pb-16 sm:pt-12 pt-0 sm:px-6 lg:px-8 lg:pb-24 lg:pt-0"
        >
          <div className="mx-auto max-w-lg">
            <div className="hidden pb-4 pt-12 lg:flex">
              <a href="/">
                <span className="sr-only">IYF Free Academy</span>
                <img
                  src="../assets/png/iyflogo-s7.png"
                  alt=""
                  className="h-20 w-auto"
                ></img>
              </a>
            </div>
            <div className="grid grid-cols-12 gap-x-4 gap-y-4">
              <div className="col-span-full">
                <div className="p-4 bg-white border border-gray-200 rounded-xl shadow-sm">
                  <b>No Results</b>
                  <div className="flex justify-between">
                    <Link
                      to="/enroll"
                      className="mt-6 rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                    >
                      Go Back
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <p className="mt-6 flex justify-center text-sm font-medium text-gray-500">
              <svg
                className="mr-1.5 h-5 w-5 text-gray-400"
                aria-hidden="true"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M10 1a4.5 4.5 0 00-4.5 4.5V9H5a2 2 0 00-2 2v6a2 2 0 002 2h10a2 2 0 002-2v-6a2 2 0 00-2-2h-.5V5.5A4.5 4.5 0 0010 1zm3 8V5.5a3 3 0 10-6 0V9h6z"
                  clipRule="evenodd"
                />
              </svg>
              We adhere to the highest standards of data security in all
              processing and storage of your information.
            </p>
          </div>
        </section>
      )}
    </>
  );
};
