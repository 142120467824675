import { useEffect, useState } from "react";
import Alert from "../../components/common/alert";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  addCourseAsync,
  selectCourseError,
  selectCourseStatus,
} from "./courseSlice";

export const AddCourse = () => {
  const [showAlert, setShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [course, setCourse] = useState({});
  const [classes, setClasses] = useState([""]);

  const courseStatus = useSelector(selectCourseStatus);
  const courseError = useSelector(selectCourseError);

  const dispatch = useDispatch();


  const addClassInput = () => {
    setClasses((arr) => {
      return [...arr, ""];
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCourse({ ...course, [name]: value });
  };

  const handleClassInputChange = (e, idx) => {
    const  value = e.target.value;
    const classNames = classes.map((el, i) => (idx === i ? value : el));
    // console.log("Classes", [...new Set(classNames)]);
    setCourse({ ...course, classes: [...new Set(classNames)] });
    setClasses(classNames);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log("Classes", course.classes);
    // console.log("course>>>", course);
    try {
      await dispatch(addCourseAsync(course)).unwrap();
    } catch (error) {
      const { data: response } = error;
      setAlertMsg({
        ...alertMsg,
        msg: response.msg,
      });
      setShowAlert(true);
    } finally {
      setAlertMsg({
        ...alertMsg,
        msg: "Course Added Successfully",
      });
      setShowAlert(true);
      e.target.reset();
    }
  };

  useEffect(() => {
    setTimeout(() => {
      if (showAlert) {
        setShowAlert(false);
      }
    }, 5000);
    // console.log("CourseStatus", courseStatus)
    // if (courseStatus === "failed") {
    //   setAlertMsg({...alertMsg, msg: "Request Failed. Try again", type: "failed"});
    //   setShowAlert(true)
    // }
  }, [showAlert]);

  return (
    <>
      <div className="px-4 sm:px-6 lg:px-8 py-8 bg-white border border-gray-200 rounded-lg shadow-sm">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-lg font-semibold text-gray-900">
              Add New Course
            </h1>
          </div>
        </div>
        <div className="mt-2 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <form className="mt-6" onSubmit={handleSubmit}>
                <div className="grid grid-cols-12 gap-x-4 gap-y-4">
                  <div className="flex justify-center  col-span-full">
                    {showAlert && (
                      <Alert
                        show={showAlert}
                        content={courseError}
                        type={courseStatus}
                      ></Alert>
                    )}
                  </div>
                  <div className="col-span-full">
                    <fieldset>
                      <legend className="block text font-medium text-gray-700">
                        Course Details
                      </legend>
                      <div className="p-4 mt-3 grid grid-cols-1 gap-x-4 gap-y-4 bg-white border border-gray-200 rounded-xl shadow-sm">
                        <div className="col-span-full sm:col-span-6">
                          <label
                            htmlFor="course_name"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Course Name
                          </label>
                          <div className="mt-1">
                            <input
                              type="text"
                              name="name"
                              id="course_name"
                              placeholder="Course Name"
                              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                        <div className="col-span-full sm:col-span-6">
                          <label
                            htmlFor="course_code"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Course Code
                          </label>
                          <div className="mt-1">
                            <input
                              type="text"
                              name="code"
                              id="course_code"
                              placeholder="Course Name"
                              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                        <div className="col-span-full">
                          <label
                            htmlFor="course_details"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Course Description
                          </label>
                          <div className="mt-1">
                            <textarea
                              minLength={50}
                              maxLength={200}
                              type="text"
                              name="details"
                              id="course_details"
                              placeholder="Course Description"
                              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                      </div>
                    </fieldset>
                  </div>

                  <div className="col-span-full">
                    <fieldset>
                      <legend className="block text font-medium text-gray-700">
                        Course Classes
                      </legend>

                      {classes.map((item, index) => (
                        <div
                          className="p-4 mt-3 bg-white border border-gray-200 rounded-xl shadow-sm"
                          key={index}
                        >
                          <label
                            htmlFor="payment-method"
                            className="block text-sm font-medium leading-6 text-gray-700"
                          >
                            Class Name
                          </label>
                          <input
                            id="class-title"
                            name="class_name"
                            type="text"
                            placeholder="Class Name"
                            value={item || ""}
                            onChange={(e) => handleClassInputChange(e, index)}
                            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                          />
                        </div>
                      ))}
                      <div className="flex items-end gap-x-3">
                        <div className="mt-3 space-y-2">
                          <button
                            type="button"
                            onClick={addClassInput}
                            className="inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-500 sm:ml-3 sm:w-auto"
                          >
                            + Add Class
                          </button>
                        </div>
                      </div>
                    </fieldset>
                  </div>
                </div>
                <div className="mt-3 flex justify-between">
                  <Link
                    to="/dashboard/courses"
                    type="submit"
                    className="mt-3 rounded-md text-base text-blue-600 px-4 py-2 hover:bg-white"
                  >
                    Cancel
                  </Link>
                  <button
                    type="submit"
                    className="mt-3 rounded-md bg-blue-700 px-5 py-3 text-sm font-semibold text-white shadow-sm hover:bg-blue-900 sm:ml-3 sm:w-auto"
                  >
                    Create
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="mx-auto max-w-lg"> */}

      {/* <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
            <button
              type="button"
              className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
            >
              Deactivate
            </button>
            <button
              type="button"
              className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
            >
              Cancel
            </button>
          </div> */}
      {/* </div> */}
      {/* <div
        className="relative z-10"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div
          className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          aria-hidden="true"
        ></div>


      </div> */}
    </>
  );
};
