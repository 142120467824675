import { useDispatch, useSelector } from "react-redux";
import {
  fetchEnrollments,
  selectEnrollments,
  selectEnrollStatus,
  selectCurrentPage,
  selectPageSize,
  selectTotalCount,
  setCurrentPage,
  selectTotalShowing,
  selectSearchTerm,
  setSearchTerm,
} from "../../features/enrollment/enrollSlice";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getUserDetails } from "../../utils/helpers/authHelpers";
import ExportEnrollmentsCSV from "./ExportCSV";

// debounce search input
function debounce(func, wait) {
  let timeout;
  return function (...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}

export const Enrollments = () => {
  const [showExportModal, setShowExportModal] = useState(false);
  const status = useSelector(selectEnrollStatus);

  // const [itemsPerPage, setItemsPerPage] = useState(200);
  // const [dataToDisplay, setDataToDisplay] = useState([]);

  const currentPage = useSelector(selectCurrentPage);
  const pageSize = useSelector(selectPageSize);
  const totalCount = useSelector(selectTotalCount);
  // const totalActiveCount = useSelector(selectTotalActive);
  // const totalPendingCount = useSelector(selectTotalPending);
  const totalShowing = useSelector(selectTotalShowing);
  const searchTerm = useSelector(selectSearchTerm);

  const [localSearchParam, setLocalSearchParam] = useState("");
   const dispatch = useDispatch();
   const user = getUserDetails();

  const total = searchTerm ? totalShowing : totalCount;
  // Calculate total number of pages
  const totalPages = Math.ceil(total / pageSize);

  // Calculate "from" and "to" values for the current page
  const from = (currentPage - 1) * pageSize + 1;
  const to = Math.min(currentPage * pageSize, total);

  const enrollments = useSelector(selectEnrollments);

    const handleNextPage = () => {
      if (currentPage * pageSize < total) {
        dispatch(setCurrentPage(currentPage + 1));
      }
    };


  const handlePrevPage = () => {
    if (currentPage > 1) {
      dispatch(setCurrentPage(currentPage - 1));
    }
  };
  // Handle search with debouncing
  const handleSearchChange = (e) => {
    setLocalSearchParam(e.target.value);
    debounceSearch(e.target.value);
  };

  const debounceSearch = debounce((searchTerm) => {
    dispatch(setSearchTerm(searchTerm));
    dispatch(setCurrentPage(1)); // Reset to page 1 when search changes
  }, 300); // 300ms debounce time

  // console.log("enl", enrollments)
  // const handleSearch = (e) => {
  //   const val = e.target.value;
  //   const data = enrollments?.filter((student) => {
  //     return val.toLowerCase() === ""
  //       ? student
  //       : student.full_name.toLowerCase().includes(val);
  //   });
  //   setDataToDisplay(data);
  // };

 

  useEffect(() => {
    dispatch(fetchEnrollments({ page: currentPage, pageSize: pageSize, search: searchTerm }));
  }, [currentPage, dispatch, pageSize, searchTerm]);

  // useEffect(() => {
  //   const start = (currentPage - 1) * itemsPerPage;
  //   const end = currentPage * itemsPerPage;
  //   if (enrollments.length !== 0) {
  //     setDataToDisplay(enrollments.slice(start, end));
  //   }
  // }, [currentPage, itemsPerPage, enrollments, searchParam]);
  return (
    <>
      {showExportModal && (
        <ExportEnrollmentsCSV
          show={showExportModal}
          handleClose={() => setShowExportModal(false)}
        />
      )}
      <div className="px-4 sm:px-6 lg:px-8">
        {user.user_type === "admin" && (
          <div className="pb-5 sm:flex sm:items-center sm:justify-between">
            <div className="mt-3 flex sm:ml-auto sm:mt-0">
              {/* <button
                type="button"
                className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              >
                Import
              </button> */}
              <button
                type="button"
                className="ml-3 inline-flex items-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                onClick={() => setShowExportModal(true)}
              >
                Export
              </button>
            </div>
          </div>
        )}

        <div className="px-4 sm:px-6 lg:px-8 py-8 bg-white border border-gray-200 rounded-lg shadow-sm">
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <h1 className="text-lg font-semibold text-gray-900">
                Student Enrollments
              </h1>
              <p className="mt-2 text-sm text-gray-700">
                A list of all student enrollments including and the respective
                courses/classes.
              </p>
            </div>
            <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
              <input
                type="search"
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                placeholder="Search enrollments..."
                value={localSearchParam}
                onChange={handleSearchChange}
              />
            </div>
          </div>
          {status === "pending" ? (
            <div className="sm:flex sm:items-center">
              <div className="sm:flex-auto">
                <h5 className="mb-2 text-center text-2xl font-bold tracking-tight text-gray-400 dark:text-white opacity-20">
                  Fetching Enrollments
                </h5>
                <div className="animate-pulse flex space-x-4">
                  <div className="flex-1 space-y-6 py-1">
                    <div className="h-2 bg-slate-600 rounded"></div>
                    <div className="space-y-3">
                      <div className="grid grid-cols-3 gap-4">
                        <div className="h-2 bg-slate-600 rounded col-span-2"></div>
                        <div className="h-2 bg-slate-600 rounded col-span-1"></div>
                      </div>
                      <div className="h-2 bg-slate-600 rounded"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : enrollments?.length !== 0 ? (
            <div className="mt-8 flow-root">
              <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead>
                      <tr>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0 bg-none"
                        >
                          #
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 bg-none"
                        >
                          Student Registration No
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 bg-none"
                        >
                          Student Name
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 bg-none"
                        >
                          Student Phone Number
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 bg-none"
                        >
                          Course
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 bg-none"
                        >
                          Class
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 bg-none"
                        >
                          Enrollments Status
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 bg-none"
                        >
                          Date Enrolled
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 bg-none"
                        >
                          Date Confirmed
                        </th>
                        <th
                          scope="col"
                          className="relative py-3.5 pl-3 pr-4 sm:pr-0 text-right text-sm font-semibold text-gray-900 bg-none"
                        >
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                      {enrollments?.map((item, index) => (
                        <tr key={index}>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-0">
                            {index + 1}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {item.registration_no}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {item.full_name}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {item.msisdn}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {item.class.course}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {item.class.class}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {item.status}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {item.created_at}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {item.updated_at}
                          </td>
                          <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                            <Link
                              to="/student-details"
                              state={{ id: item.student_id }}
                              className="mx-2 rounded-md border p-2 text-blue-600 hover:text-blue-900"
                              onClick={() => {}}
                            >
                              View
                            </Link>
                            {/* <Link
                              to="/dashboard/edit-student"
                              value={item.id}
                              className="mx-2 rounded-md border p-2 text-blue-600 hover:text-blue-900"
                              onClick={() => {}}
                            >
                              Edit
                            </Link> */}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="flex flex-1 justify-between sm:hidden">
                  <button
                    // href="/"
                    className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                  >
                    Previous
                  </button>
                  <button
                    href="/"
                    className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                  >
                    Next
                  </button>
                </div>
                <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
                  <div>
                    <p className="text-sm text-gray-700">
                      Showing <span className="font-medium">{from}</span> to{" "}
                      <span className="font-medium"> {to}</span> of
                      <span className="font-medium"> {total}</span>
                    </p>
                  </div>
                  <div>
                    <nav
                      className="isolate inline-flex -space-x-px rounded-md shadow-sm"
                      aria-label="Pagination"
                    >
                      <button
                        type="button"
                        onClick={handlePrevPage}
                        disabled={currentPage === 1}
                        // onClick={() => {
                        //   if (currentPage <= 1) {
                        //     return;
                        //   } else {
                        //     setCurrentPage((prev) => prev - 1);
                        //   }
                        // }}
                        className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                      >
                        <span className="sr-only">Previous</span>
                        <svg
                          className="h-5 w-5"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            fillRule="evenodd"
                            d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </button>
                      {/* Array(Math.round(registrations.length / itemsPerPage)) */}
                      {Array.from(Array(Math.round(total / pageSize)))
                        .map((e, i) => i + 1)
                        .map((val, idx) => {
                          return (
                            <button
                              type="button"
                              key={idx}
                              onClick={() => dispatch(setCurrentPage(val))}
                              aria-current="page"
                              className={
                                currentPage === val
                                  ? "relative z-10 inline-flex items-center bg-indigo-600 px-4 py-2 text-sm font-semibold text-white focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                  : "relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                              }
                            >
                              {val}
                            </button>
                          );
                        })}
                      <button
                        type="button"
                        onClick={handleNextPage}
                        disabled={currentPage === totalPages}
                        // onClick={() => {
                        //   if (
                        //     currentPage >=
                        //     registrations.length / itemsPerPage
                        //   ) {
                        //     console.log("exceeded");
                        //     return;
                        //   } else {
                        //     setCurrentPage((prev) => prev + 1);
                        //   }
                        // }}
                        className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                      >
                        <span className="sr-only">Next</span>
                        <svg
                          className="h-5 w-5"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            fillRule="evenodd"
                            d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </button>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <p>No Enrollments Found</p>
          )}
        </div>
      </div>
    </>
  );
};
