import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCourses,
  selectCourses,
  selectCourseStatus,
} from "../courses/courseSlice";
import Alert from "../../components/common/alert";
import { addSession, selectSessionsError, selectSessionsStatus, updateSession } from "./sessionSlice";

export const AddSessionForm = ({session}) => {
  const [showClasses, setShowClasses] = useState(false);
  const [showSessions, setShowSessions] = useState(false);
  const [classes, setClasses] = useState([]);
  const [sessions, setSessions] = useState({});
  // const [classId, setClassId] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState(false);
  // const [showError, setShowError] = useState(false);
  const [data, setData] = useState({});

  const dispatch = useDispatch();

  const courseStatus = useSelector(selectCourseStatus);
  const courses = useSelector(selectCourses);
  const sessionError = useSelector(selectSessionsError);
  const sessionsStatus = useSelector(selectSessionsStatus);

  const [sessionInputs, setSessionInputs] = useState([
    {
      day: "",
      start_at: "",
      end_at: "",
    },
  ]);
  console.log("session, ", session);

  const addSessionInput = () => {
    console.log("clicked!!!");
    setSessionInputs((arr) => {
      return [...arr, {}];
    });
  };

  const handleInputChange = (id) => (e) => {
    const { name, value } = e.target;
    if (!session) {
      // new sessions data
    console.log(id, value, name);
    const sessionValues = sessionInputs.map((el, idx) =>
      idx === id ? { ...el, [name]: value } : el
    );
    // console.log("sessionInputs", sessionInputs);
    setSessions({...sessions, sessions: sessionValues});
    setSessionInputs(sessionValues);

    // { ...session, [name]: value });
    }else{
      // updating sessions data
      setData({...data, [name]: value, id: session.id })
    }
  };

  const handleCourseInput = (e) => {
    // setCourseId(e.target.value);
    const course = courses.find(
      (course) => course.id === parseInt(e.target.value)
    );
    if (course && course.classes.length !== 0) {
      // setCourseItem(course);
      setClasses(course.classes);
      setShowClasses(true);
    }
  };
  const handleClassInputChange = (e) => {
    // setClassId(e.target.value);
    if (classes.length !== 0) {
      const class_ = classes.find(
        (item) => item.id === parseInt(e.target.value)
      );
      // console.log("class_", class_);
      if (class_) {
        console.log("classId", class_.id);
        // setSessionInputs([...sessionInputs, {}])
        setSessions({ ...sessions, class_id: class_.id });
        setShowSessions(true);
      } else {
        setShowSessions(false);
      }
    }
  };

  
  const handleSubmit = async(e) => {
    e.preventDefault();
    console.log("data:", sessions);
    try {
      if (session) {
        await dispatch(updateSession(data))
      }else{
        await dispatch(addSession(sessions)); 
      }
    } catch (error) {
      const { data: response } = error;
      setAlertMsg({
        ...alertMsg,
        msg: response.msg,
      });
      setShowAlert(true);
    } finally {
      setAlertMsg({
        ...alertMsg,
        msg: "Session added Successfully",
      });
      setShowAlert(true);
      setSessionInputs([]);
      setSessions({})
      setClasses([]);
      e.target.reset();
    }
  };
  useEffect(() => {
    setTimeout(() => {
      if (showAlert) {
        setShowAlert(false);
      }
    }, 5000);
    // console.log("sessionsLen", sessions.length);
    if (courseStatus === "idle") {
      dispatch(fetchCourses());
      // console.log("CourseID", courseItem);
    }
    if (session) {
      setShowClasses(true);
      setShowSessions(true);
    }
  }, [courseStatus, dispatch, session, showAlert]);
  return (
    <section
      aria-labelledby="payment-heading"
      className="flex-auto overflow-y-auto px-4 pb-16 sm:pt-12 pt-0 sm:px-6 lg:px-8 lg:pb-24 lg:pt-0"
    >
      <div className="mx-auto max-w-6xl">
        <form className="mt-6" onSubmit={handleSubmit}>
          <div className="grid grid-cols-12 gap-x-4 gap-y-4">
            {session ? (
              <div className="col-span-full">
                <p className="block text-xl font-medium text-gray-700 mb-4 text-center underline">
                  Update Session
                </p>
                {/* <div className="col-span-full"> */}
                  <p className="block text-xl font-normal text-gray-700 mb-4 text-center">
                    Course: {session.course} | class: {session.class} |{" "}
                    {session.start_at} - {session.end_at}
                  </p>
                {/* </div> */}
              </div>
            ) : (
              <div className="col-span-full">
                <p className="block text-xl font-medium text-gray-700 mb-4 text-center underline">
                  Add Class Session
                </p>
              </div>
            )}

            <div className="flex justify-center col-span-full">
              {showAlert && (
                <Alert
                  show={showAlert}
                  content={sessionError}
                  type={sessionsStatus}
                ></Alert>
              )}
            </div>

            <div className="col-span-full">
              <div className="p-4 bg-white border border-gray-200 rounded-xl shadow-sm">
                <label
                  htmlFor="select-course"
                  className="block text font-medium text-gray-700"
                >
                  Select Course
                </label>
                <div className="mt-1">
                  <select
                    id="select-course"
                    name="course"
                    className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-700 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
                    defaultValue={session?.course_id}
                    onChange={handleCourseInput}
                  >
                    <option>Select Course</option>
                    {courses.map((course, index) => (
                      <option key={index} value={course.id}>
                        {course.course_name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>

            {showClasses && (
              <div className="col-span-full">
                <div className="p-4 bg-white border border-gray-200 rounded-xl shadow-sm">
                  <label
                    htmlFor="session"
                    className="block text font-medium text-gray-700"
                  >
                    Select Class
                  </label>
                  <div className="mt-1">
                    <select
                      id="class_"
                      name="class"
                      placeholder="Select Class"
                      defaultValue={session?.class_id}
                      onChange={handleClassInputChange}
                      className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-700 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
                    >
                      <option>Select Class</option>
                      {classes.map((class_, index) => (
                        <option key={index} value={class_.id}>
                          {class_.class_name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            )}

            {showSessions && (
              <div className="col-span-full">
                <div className="p-4 mt-3 bg-white border border-gray-200 rounded-xl shadow-sm">
                  <fieldset>
                    <legend className="block text font-medium text-gray-700">
                      Class Sessions
                    </legend>
                    {sessionInputs.map((item, index) => (
                      <div className="mt-3 space-y-2" key={index}>
                        <div className="flex items-center gap-x-3">
                          <label
                            htmlFor="session-day"
                            className="block text-sm font-medium leading-6 text-gray-700"
                          >
                            Day
                          </label>
                          <select
                            id="session-day"
                            name="day"
                            placeholder="Select Class"
                            value={item.day || ""}
                            defaultValue={session?.day}
                            onChange={(e) => handleInputChange(index)(e)}
                            className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-700 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
                          >
                            <option>Select Day</option>
                            <option key="sat" value="Saturday">
                              Saturday
                            </option>
                            <option key="sun" value="Sunday">
                              Sunday
                            </option>
                            <option key="chapel" value="Chapel">
                              Sunday Chapel
                            </option>
                          </select>
                        </div>
                        <div className="flex items-end gap-x-3">
                          <div className="flex items-center gap-x-3">
                            <label
                              htmlFor="session-start"
                              className="block text-sm font-medium leading-6 text-gray-700"
                            >
                              Start At:
                            </label>
                            <input
                              id="session-start"
                              name="start_at"
                              type="time"
                              min="07:00"
                              max="18:00"
                              defaultValue={session?.start_at}
                              placeholder="Start Time"
                              // value={item.start_at || ""}
                              onChange={(e) => handleInputChange(index)(e)}
                              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                            ></input>
                          </div>
                          <div className="flex items-center gap-x-3">
                            <label
                              htmlFor="session-end"
                              className="block text-sm font-medium leading-6 text-gray-700"
                            >
                              End At:
                            </label>
                            <input
                              id="session-end"
                              name="end_at"
                              type="time"
                              min="07:00"
                              max="18:00"
                              defaultValue={session?.end_at}
                              // value={item.end_at || ""}
                              placeholder="Session End Time"
                              onChange={(e) => handleInputChange(index)(e)}
                              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                            ></input>
                          </div>
                        </div>
                      </div>
                    ))}
                  </fieldset>
                  {!session && (
                    <div className="mt-3 space-y-2">
                      <button
                        type="button"
                        onClick={() => addSessionInput()}
                        className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-500 sm:ml-3 sm:w-auto"
                      >
                        + Add Session
                      </button>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
          {session ? (
            <div className="flex justify-between">
              <button
                type="submit"
                className="mt-6 rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:bg-gray-500"
              >
                Save
              </button>
            </div>
          ) : (
            <div className="flex justify-between">
              <button
                type="submit"
                className="mt-6 rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:bg-gray-500"
              >
                Submit
              </button>
            </div>
          )}
        </form>
      </div>
    </section>
  );
};
