import { get, post, put, patch, deleteRequest } from "./base";

// const BASE_URL = "http://127.0.0.1:5000/api/v1";
// const BASE_URL = "https://5b85-102-219-208-127.ngrok-free.app/api/v1";

const Apis = {
  login: (user_data) => post("/auth/login", user_data),
  changePassword: (data) => post("/auth/change-password", data),

  registerStudent: (data) => post("/students/register-student", data),
  updateStudent: (data) => patch("/students/update-student", data),
  enroll: (data) => post("/students/enroll", data),
  verifyEnrollment: (data) => post("/students/verify-enrollment", data),
  updateEnrollment: (data) => patch("/students/update-enrollment", data),
  deleteEnrollment: (param) =>
    deleteRequest("/students/delete-enrollment", param),
  getRegistrations: (params) => get("/students", params),
  getRegistrationCount: () => get("/students/registrations-count"),
  getEnrollmentCount: () => get("/students/enrollments-count"),
  fetchStudent: (param) => get("/students/get-student", param),
  exportRegistrations: (params) => get("/students/export", params),
  // fetchAllStudents: (params) =>
  //   get("/students", params, { validateStatus: false }),
  getCourses: () => get("/courses"),
  getCourse: (params) => get("/courses", params),
  addCourse: (course) => post("/courses/add-course", course),
  updateCourse: (data) => patch("/courses/update-course", data),
  deleteCourse: (course_id) =>
    deleteRequest("/courses/update-course" + course_id),

  addClass: (params) => post("/classes/create-class", params),
  getAllClasses: (params) => get("/classes", params),
  getClass: (params) => get("/classes/fetch-class", params),
  removeClass: (params) => deleteRequest("/classes/delete", params),
  updateClass: (params) => patch("/classes/update-class", params),

  // getSessions: (course_id) =>
  //   get("/sessions?course_id=" + course_id),
  fetchOneSession: (params) => get("/sessions/get-session", { params: params }),
  getSessions: (params) => get("/sessions", params),
  addSessions: (sessions) => post("/sessions/add-sessions", sessions),
  assignTutorSession: (data) => post("/sessions/assign-tutor", data),
  updateSession: (data) => patch("/sessions/update-session", data),
  deleteSession: (params) => post("/sessions/delete-session", params),

  getTutors: (params) => get("/tutors", params),
  getTutor: (id) => get("/tutors?q" + id),
  addTutor: (tutor) => post("/tutors/create-tutor", tutor),
  updateTutor: (tutor_id) => put("/tutors/update-tutor" + tutor_id),

  getCashiers: (params) => get("/cashiers", params),
  // getCashier: (param) => get("/cashiers", { params: param }),
  addCashier: (cashier) => post("/cashiers/add-cashier", cashier),
  updateCashier: (data) => put("/cashiers/update-cashier", data),

  // attendance registers
  createRegister: (data) => post("/attendance-registers/add-register", data),
  fetchAttendance: (params) => get("/attendance-registers", { params: params }),
  updateRegister: (data) => post("/attendance-registers/update-register", data),

  // payments
  makePayment: (data) => post("/payments/pay", data),
  getTransactions: (params) => get("/payments/transactions", params),
  getSTKPushRequests: (params) => get("/payments/express-requests", params),
  getMpesaClassicRequests: (params) =>
    get("/payments/mpesa-classic-requests", params),
  getPaymentRequests: (params) => get("/payments/payment-requests", params),
  getTransactionQueryRequests: (params) =>
    get("/payments/transaction-query-requests", params),
  getEnrollments: (params) => get("/students/enrollments", params),
  getStudentEnrollment: (params) =>
    get("/students/get-student-enrollment", params),

  getClassEnrollment: (params) => get("/students/get-class-enrollment", params),
};

export default Apis;
