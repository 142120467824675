import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import dayjs from 'dayjs';
import {
  addRegisterAsync,
  fetchAttendanceRegister,
  selectAttendance,
} from "../../features/attendance_registers/attendanceSlice";
import Alert from "../../components/common/alert";
import {
  fetchOne,
  selectSessionsStatus,
  selectSessionById,
} from "../../features/sessions/sessionSlice";

export const AttendanceRegister = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { sessionId, date } = location.state || {};

  // console.log("Date", date)

  // const sessionStatus = useSelector(selectSessionsStatus);
  const maxDate = new Date().toISOString().slice(0, -14);

  // const status = useSelector(selectSessionsStatus);
  const session = useSelector((state) => selectSessionById(state, sessionId));
  const attendance = useSelector(selectAttendance);

  // const [show, setShow] = useState({
  //   showStudents: false,
  //   showAlert: false,
  // });
  const [attendanceState, setAttendanceState] = useState({
    present: [],
    absent: [],
    presentCount: 0,
    absentCount: 0,
  });

  const [alert, setAlert] = useState({ show: false, msg: "", type: "" });
  const [registerState, setRegisterState] = useState("new");
  const [students, setStudents] = useState([]);
  // const [sessionDate, setSessionDate] = useState(dayjs().format("DD/MM/YYYY"));
  const [sessionDate, setSessionDate] = useState("");
  const [searchParam, setSearchParam] = useState("");

  // const [state, setState] = useState({
  //   sessionDate: "",
  //   searchParam: "",
  //   alertMsg: {}
  // });
  // const [showStudents, setShowStudents] = useState(false);
  // const [present, setPresent] = useState([]);
  // const [absent, setAbsent] = useState([]);
  // const [presentCount, setPresentCount] = useState(0);
  // const [absentCount, setAbsentCount] = useState(0);
  // const [students, setStudents] = useState([]);
  // const [marked, setMarked] = useState([]);
  // const [markedStates, setMarkedStates] = useState([]);
  // const [states, setStates] = useState([]);
  // const [registerState, setRegisterState] = useState("new");
  // const [showAlert, setShowAlert] = useState(false);
  // const [alertMsg, setAlertMsg] = useState({});
  // const [sessionDate, setSessionDate] = useState(
  //   dayjs().format("DD/MM/YYYY")
  // );
  // dayjs().format("DD/MM/YYYY");
  // const [updates, setUpdates] = useState({ present: [], absent: [] });
  // const [searchParam, setSearchParam] = useState("");
  // const [register, setRegister] = useState({});

  // useScript("https://cdn.jsdelivr.net/npm/flowbite@2.5.1/dist/flowbite.min.js");

  let today = new Date().toLocaleDateString(undefined, {
    weekday: "long",
    year: "numeric",
    month: "numeric",
    day: "numeric",
  });
  useEffect(() => {
    dispatch(fetchOne({ sessionId: sessionId }));
    if (date) {
      dispatch(fetchAttendanceRegister({ sessionId, day: date }));
      setSessionDate(date);
    }
  }, [dispatch, sessionId, date]);

  useEffect(() => {
    if (attendance) {
      if (attendance[sessionDate]) {
        const { students, present_count, absent_count, status } =
          attendance[sessionDate];
        setStudents(students || []);
        setRegisterState(status);
        const present = [];
        const absent = [];

        students.forEach((student) => {
          if (student.state === "present") present.push(student.id);
          if (student.state === "absent") absent.push(student.id);
        });

        setAttendanceState((prev) => ({
          ...prev,
          present: present,
          absent: absent,
          presentCount: present_count,
          absentCount: absent_count,
        }));
      } else {
        setAttendanceState((prev) => ({
          ...prev,
          present: [],
          absent: [],
          presentCount: 0,
          absentCount: 0,
        }));
        setStudents(session?.students || []);
        setRegisterState("new");
      }
      // setStudents(attendance[date].students || []);
    }
  }, [attendance, date, session, sessionDate]);

  const handleMarkAttendance = useCallback((studentId, status) => {
    setAttendanceState((prev) => {
      const isPresent = status === "present";
      const wasPresent = prev.present.includes(studentId);
      const wasAbsent = prev.absent.includes(studentId);

      let updatedPresent = prev.present;
      let updatedAbsent = prev.absent;
      let updatedPresentCount = prev.presentCount;
      let updatedAbsentCount = prev.absentCount;

      if (isPresent && !wasPresent) {
        // Add to present list and remove from absent if necessary
        updatedPresent = [...prev.present, studentId];
        updatedPresentCount += 1;

        if (wasAbsent) {
          updatedAbsent = prev.absent.filter((id) => id !== studentId);
          updatedAbsentCount -= 1;
        }
      } else if (!isPresent && !wasAbsent) {
        // Add to absent list and remove from present if necessary
        updatedAbsent = [...prev.absent, studentId];
        updatedAbsentCount += 1;

        if (wasPresent) {
          updatedPresent = prev.present.filter((id) => id !== studentId);
          updatedPresentCount -= 1;
        }
      }

      return {
        present: updatedPresent,
        absent: updatedAbsent,
        presentCount: updatedPresentCount,
        absentCount: updatedAbsentCount,
      };
    });
  }, []);

  // const update = (value, state) => {
  //   // const value = e.target.value;
  //   // console.log("id:", value, "state", state);
  //   switch (state) {
  //     case "absent":
  //       setAttendanceData({
  //         ...attendanceState,
  //         absentCount: attendanceState.absentCount + 1,
  //       });
  //       setAttendanceData({
  //         ...attendanceState,
  //         presentCount: attendanceState.presentCount + 1,
  //       });
  //       // setPresentCount(presentCount - 1);
  //       // setAbsentCount(absentCount + 1);
  //       // setPresentCount(presentCount - 1);
  //       if (!updates.absent.find((id) => id === value)) {
  //         setUpdates({ ...updates, absent: [...updates.absent, value] });
  //         setAttendanceData({
  //           ...attendanceState,
  //           markedSates: attendanceState.markedStates.map((item) =>
  //             item.id === value ? { ...item, state: "absent" } : item
  //           ),
  //         });
  //         // setMarkedStates(
  //         //   markedStates.map((item) =>
  //         //     item.id === value ? { ...item, state: "absent" } : item
  //         //   )
  //         // );
  //       }
  //       if (updates.present.find((item) => item === value)) {
  //         setUpdates({
  //           ...updates,
  //           present: updates.present.filter((x) => x !== value),
  //         });
  //       }

  //       break;
  //     case "present":
  //       setAttendanceData({
  //         ...attendanceState,
  //         presentCount: attendanceState.presentCount + 1,
  //       });
  //       setAttendanceData({
  //         ...attendanceState,
  //         presentCount: attendanceState.presentCount + 1,
  //       });
  //       // setPresentCount(presentCount - 1);
  //       // setAbsentCount(presentCount + 1);
  //       // setPresentCount(presentCount - 1);
  //       if (!updates.present.find((id) => id === value)) {
  //         setUpdates({ ...updates, present: [...updates.present, value] });
  //         setAttendanceData({
  //           ...attendanceState,
  //           markedSates: attendanceState.markedStates.map((item) =>
  //             item.id === value ? { ...item, state: "present" } : item
  //           ),
  //         });
  //         // setMarkedStates(
  //         //   markedStates.map((item) =>
  //         //     item.id === value ? { ...item, state: "present" } : item
  //         //   )
  //         // );
  //       }
  //       if (updates.absent.find((item) => item === value)) {
  //         setUpdates({
  //           ...updates,
  //           absent: updates.absent.filter((x) => x !== value),
  //         });
  //       }

  //       // setPresentCount(presentCount + 1);
  //       // setAbsentCount(absentCount - 1);
  //       // if (!updates.present.find((id) => id === value)) {
  //       //   const newPresent = [...updates.present, value];
  //       //   setUpdates({ ...updates, present: newPresent });
  //       //   setMarkedStates(
  //       //     markedStates.map((item) =>
  //       //       item.id === value ? { ...item, state: "present" } : item
  //       //     )
  //       //   );
  //       // }
  //       // if (updates.absent.find((item) => item === value)) {
  //       //   setUpdates({
  //       //     ...updates,
  //       //     absent: updates.absent.filter((x) => x !== value),
  //       //   });
  //       // }

  //       break;
  //     default:
  //       break;
  //   }
  // };

  const handleDateSelect = async (e) => {
    let selectedDay = new Date(e.target.value).toLocaleDateString("es-Es");
    if (selectedDay.split("/")[0].length === 1) {
      selectedDay = `0${selectedDay}`;
    }
    setSessionDate(selectedDay);
    try {
      await dispatch(fetchAttendanceRegister({ sessionId, day: selectedDay }))
        .unwrap()
        .then(({ data }) => {
          // if (data) {
          //   console.log("data!!!", data);
          //   // setRegisterState("draft");
          //   // const register = attendance[selectedDay];
          //   console.log("Yes!!!!", attendance);
          // if (attendance) {
          const attendance = data[selectedDay];
          // console.log("attendance ===", attendance);
          const { students, present_count, absent_count, status } = attendance;
          setStudents(students || []);
          setRegisterState(status);
          const present = [];
          const absent = [];

          students.forEach((student) => {
            if (student.state === "present") present.push(student.id);
            if (student.state === "absent") absent.push(student.id);
          });

          setAttendanceState((prev) => ({
            ...prev,
            present: present,
            absent: absent,
            presentCount: present_count,
            absentCount: absent_count,
          }));
          // } else {
          //   setAttendanceState((prev) => ({
          //     ...prev,
          //     present: [],
          //     absent: [],
          //     presentCount: 0,
          //     absentCount: 0,
          //   }));
          //   setStudents(session?.students || []);
          // }
          // }
        });
    } catch (error) {
      console.log("Error", error);
      setAttendanceState((prev) => ({
        ...prev,
        present: [],
        absent: [],
        presentCount: 0,
        absentCount: 0,
      }));
      setStudents(session?.students || []);
      setRegisterState("new");
    }
    // console.log("selectedDay ===", selectedDay);
    // dispatch(fetchAttendanceRegister({ sessionId, day: selectedDay }))
    // setState({ ...state, sessionDate: selectedDay });
    // setSessionDate(selectedDay);
    // if (attendance) {
    //   // setRegisterState("draft");
    //   // const register = attendance[selectedDay];

    //   if (attendance[selectedDay]) {
    //     console.log("Yes!!!!");
    //     console.log("attendance ===", attendance);
    //       const students = attendance[selectedDay].students;
    //       const presentCount = attendance[selectedDay].present_count;
    //       const absentCount = attendance[selectedDay].absent_count;
    //       setStudents(students || []);
    //       const present = [];
    //       const absent = [];

    //       students.forEach((student) => {
    //         if (student.state === "present") present.push(student.id);
    //         if (student.state === "absent") absent.push(student.id);
    //       });

    //       setAttendanceState((prev) => ({
    //         ...prev,
    //         present: present,
    //         absent: absent,
    //         presentCount: presentCount,
    //         absentCount: absentCount,
    //       }));
    //     } else {
    //        setAttendanceState((prev) => ({
    //          ...prev,
    //          present: [],
    //          absent: [],
    //          presentCount: 0,
    //          absentCount: 0,
    //        }));
    //       setStudents(session?.students || []);
    //     }
    //     // setStudents(attendance[date].students || []);

    //   // if (register) {
    //   //   // setAttendanceData({
    //   //   //   ...attendanceState,
    //   //   //   absentCount: register.absent_count,
    //   //   // });
    //   //   // setAttendanceData({
    //   //   //   ...attendanceState,
    //   //   //   presentCount: register.present_count,
    //   //   // });
    //   //   setAttendanceData({
    //   //     ...attendanceState,
    //   //     absentCount: register.absent_count,
    //   //     presentCount: register.present_count,
    //   //     status: register.status,
    //   //     marked: register.marked,
    //   //     markedStates: register.marked,
    //   //     states: new Array(register.unmarked_count).fill("unmarked"),
    //   //     students: register.unmarked,
    //   //   });

    //   //   setShow({
    //   //     ...show,
    //   //     showStudents: true,
    //   //   });
    //   //   // setAbsentCount(register.absent_count);
    //   //   // setPresentCount(register.present_count);
    //   //   // setRegisterState(register.status);

    //   //   // setMarkedStates(register.marked);
    //   //   // setMarked(register.marked);
    //   //   // setStudents(register.unmarked);
    //   //   // setStates(new Array(register.unmarked_count).fill("unmarked"));
    //   //   // setShowStudents(true);
    //   // } else {
    //   //   setAttendanceData({
    //   //     ...attendanceState,
    //   //     absentCount: 0,
    //   //     presentCount: 0,
    //   //     status: "new",
    //   //     marked: [],
    //   //     markedStates: [],
    //   //     states: new Array(session?.students.length).fill("unmarked"),
    //   //     students: session?.students,
    //   //   });
    //   //   setShow({
    //   //     ...show,
    //   //     showStudents: true,
    //   //   });

    //   // setAbsentCount(0);
    //   // setPresentCount(0);
    //   // setRegisterState("new");
    //   // setMarkedStates([]);
    //   // setMarked([]);
    //   // setStates(new Array(session?.students.length).fill("unmarked"));
    //   // setStudents(session?.students);
    //   // setShowStudents(true);
    // }
    // const markedStates = attendance.marked.map((item) => ({
    //   id: item.id,
    //   state: item.attendance,
    // }));
    // attendance.some(({ status }) => {
    //   if (status === "draft") {
    //     setRegisterState("draft");
    //     return true;
    //   } else {
    //     setRegisterState("saved");
    //     return true;
    //   }
    // });
    // console.log("markedStates:", markedStates);
    // setPresentCount(
    //   markedStates.filter((item) => item.state === "present").length
    // );
    // setAbsentCount(
    //   markedStates.filter((item) => item.state === "absent").length
    // );
    // setMarkedStates(markedStates);
    // const markedStudentIDs = attendance.map((item) => item.student_id);
    // // setMarked(markedStudentIDs);
    // console.log("MarkedIDs", markedStudentIDs);
    // const markedStudents = session?.students.filter((item) =>
    //   markedStudentIDs.includes(item.id)
    // );
    // setMarked(markedStudents);
    // console.log("Marked STudents", markedStudents);
    // const unmarkedStudents = session?.students.filter(
    //   (item) => !markedStudentIDs.includes(item.id)
    // );
    // console.log("Not Marked", unmarkedStudents);
    // setStates(new Array(unmarkedStudents.length).fill("unmarked"));
    // setStudents(unmarkedStudents);
    // setShowStudents(true);
    // } else {
    //   setAttendanceData({
    //     ...attendanceState,
    //     absentCount: 0,
    //     presentCount: 0,
    //     status: "new",
    //     marked: [],
    //     markedStates: [],
    //     states: new Array(session?.students.length).fill("unmarked"),
    //     students: session?.students,
    //   });
    //   setShow({
    //     ...show,
    //     showStudents: true,
    //   });
    // setAbsentCount(0);
    // setPresentCount(0);
    // setRegisterState("new");
    // setMarkedStates([]);
    // setMarked([]);
    // setStates(new Array(session?.students.length).fill("unmarked"));
    // setStudents(session?.students);
    // setShowStudents(true);
    // }
  };

  // const saveDraft = async () => {
  //   // if (present.length !== 0 || absent.length != 0) {
  //   try {
  //     const payload = {
  //       present: attendanceState.present,
  //       absent: attendanceState.absent,
  //       session_id: session?.id,
  //       day: state.sessionDate,
  //       status: "draft",
  //     };
  //     if (updates.present.length !== 0 || updates.absent.length !== 0) {
  //       payload.updates = updates;
  //     }
  //     const { msg, status } = await dispatch(
  //       addRegisterAsync(payload)
  //     ).unwrap();
  //     if (status !== 200) {
  //       setState({
  //         ...state,
  //         alertMsg: {
  //           msg: registerError,
  //           type: "failed",
  //         },
  //       });
  //       setShow({
  //         ...show,
  //         showAlert: true,
  //       });
  //       // setAlertMsg({
  //       //   ...alertMsg,
  //       //   msg: registerError,
  //       //   type: "failed",
  //       // });
  //       // setShowAlert(true);
  //     } else {
  //       navigate("/dashboard");
  //     }
  //   } catch (error) {
  //     console.log("SaveDraft Error", error);
  //     const { data: response } = error;
  //     setState({
  //       ...state,
  //       alertMsg: {
  //         msg: response.msg,
  //         type: "failed",
  //       },
  //     });
  //     setShow({
  //       ...show,
  //       showAlert: true,
  //     });
  //     // setAlertMsg({
  //     //   ...alertMsg,
  //     //   msg: response.msg,
  //     //   type: "failed",
  //     // });
  //     // setShowAlert(true);
  //   }

  //   // finally {
  //   //   setAlertMsg({
  //   //     ...alertMsg,
  //   //     msg: registerError,
  //   //     type: "failed",
  //   //   });
  //   //   setShowAlert(true);
  //   // e.target.reset();
  //   // setAttendance({});
  //   // }
  //   // } else {
  //   //   setShowAlert(true);
  //   // }
  // };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();

  //   // console.log("present", present);
  //   // console.log("absent", absent);

  //   console.log("updatesPresent", updates.present);
  //   console.log("updatesAbsent", updates.absent);

  //   // if (present.length !== 0 || absent.length !== 0) {
  //   try {
  //     const payload = {
  //       present: attendanceState.present,
  //       absent: attendanceState.absent,
  //       session_id: session?.id,
  //       day: state.sessionDate,
  //       status: "final",
  //     };
  //     if (updates.present.length !== 0 || updates.absent.length !== 0) {
  //       payload.updates = updates;
  //     }
  //     await dispatch(addRegisterAsync(payload))
  //       .unwrap()
  //       .then(({ data, msg, status }) => {
  //         console.log("submit response", msg);
  //         setState({
  //           ...state,
  //           alertMsg: {
  //             msg: msg,
  //             type: "success",
  //           },
  //         });
  //         setShow({
  //           ...show,
  //           showAlert: true,
  //         });

  //         // setAlertMsg({
  //         //   ...alertMsg,
  //         //   msg: msg,
  //         // });
  //         // setShowAlert(true);
  //         // if (status === 200) {
  //         //   fetchSession();
  //         //   e.target.reset();
  //         // }
  //       });
  //   } catch (error) {
  //     console.log(error);
  //     const { data: response } = error;
  //     setState({
  //       ...state,
  //       alertMsg: {
  //         msg: response.msg,
  //         type: "failed",
  //       },
  //     });
  //     setShow({
  //       ...show,
  //       showAlert: true,
  //     });

  //     // setAlertMsg({
  //     //   ...alertMsg,
  //     //   msg: response.msg,
  //     // });
  //     // setShowAlert(true);
  //   }

  //   // finally {
  //   //   setAlertMsg({
  //   //     ...alertMsg,
  //   //     msg: registerError,
  //   //   });
  //   //   setShowAlert(true);
  //   //   e.target.reset();
  //   // }

  // };

  // useEffect(() => {
  //   console.log("NOt session", session_id);
  //   // if (!session) {
  //     // console.log("NOt session")
  //     // }
  //   }, [dispatch, session_id])

  // console.log("Day-Session", session_id, sessionStatus);
  //   useEffect(() => {
  //   dispatch(fetchOne({ params: { sessionId: session_id } }));
  //   if (!attendance) {
  //     console.log("fire!!!")
  //     dispatch(
  //       fetchAttendanceRegister({
  //         params: { sessionId: session_id, day: state.sessionDate },
  //       })
  //     );
  //   }
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // console.log("state", registerState);
      // const payload = {
      //   session_id: sessionId,
      //   date: sessionDate,
      //   present: attendanceState.present,
      //   absent: attendanceState.absent,
      // };
      // console.log("payload", payload);
      await dispatch(
        addRegisterAsync({
          session_id: sessionId,
          date: sessionDate,
          present: attendanceState.present,
          absent: attendanceState.absent,
          status: "saved",
        })
      ).unwrap();

      setAlert({
        show: true,
        msg: "Attendance registered successfully!",
        type: "success",
      });
      // setTimeout(() => navigate("/sessions"), 2000);
    } catch (error) {
      setAlert({
        show: true,
        msg: error.message || "Failed to register attendance.",
        type: "error",
      });
    }
    finally{
       setTimeout(() => {
        navigate(-1) || navigate("/dashboard")
      }, 3000);
    }
  };

  const handleSaveDraft = async (e) => {
    e.preventDefault();
    try {
      console.log("stateDraft", registerState);
      const payload = {
        session_id: sessionId,
        date: sessionDate,
        present: attendanceState.present,
        absent: attendanceState.absent,
      };
      console.log("payload", payload);
      await dispatch(
        addRegisterAsync({
          session_id: sessionId,
          date: sessionDate,
          present: attendanceState.present,
          absent: attendanceState.absent,
          status: "draft",
        })
      ).unwrap();

      setAlert({
        show: true,
        msg: "Attendance registered successfully!",
        type: "success",
      });
      // setTimeout(() => navigate("/sessions"), 2000);
    } catch (error) {
      setAlert({
        show: true,
        msg: error.message || "Failed to register attendance.",
        type: "error",
      });
    }
  };

  //   // setTimeout(() => {
  //   //   setShow({
  //   //     ...show,
  //   //     showAlert: false,
  //   //   });
  //   // }, 5000);
  // }, [state.sessionDate, dispatch, session_id, show, attendance]);

  return (
    <>
      {/* <div className="pb-4 pt-12 lg:flex">
          <a href="/">
            <span className="sr-only">IYF Free Academy</span>
            <img
              src="../assets/png/iyflogo-s7.png"
              alt=""
              className="h-12 lg:h-20 w-auto"
            />
          </a><div className="px-4 sm:px-6 lg:px-8">
        </div> */}
      {/* <SuccessSVG/> */}
      <section className="justify-items-center flex justify-center overflow-y-auto pb-16 sm:pt-12 pt-0 sm:px-6 lg:px-8 lg:pb-24 lg:pt-0">
        {/* <!-- Start Student Data --> */}
        {!session ? (
          <div className="mx-auto max-w-lg">
            <div className="relative items-center block max-w-sm p-6 bg-white border border-gray-100 rounded-lg shadow-md dark:bg-gray-800 dark:border-gray-800 dark:hover:bg-gray-700">
              <h5 className="mb-2 text-center text-2xl font-bold tracking-tight text-gray-400 dark:text-white opacity-20">
                Loading ...
              </h5>
              <div
                role="status"
                className="absolute -translate-x-1/2 -translate-y-1/2 top-2/4 left-1/2"
              >
                <svg
                  aria-hidden="true"
                  className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
                <span className="sr-only">Processing your request ...</span>
              </div>
            </div>
          </div>
        ) : (
          <div className="grid grid-cols-12 lg:w-1/2 justify-center">
            <div className="p-4 bg-white border border-gray-200 rounded-tl-xl rounded-tr-xl col-span-full">
              <div className="flex justify-start items-start">
                <button
                  onClick={() => navigate(-1) || navigate("/dashboard")}
                  className="py-1 px-2 bg-gray-100 mr-4 rounded-md
                hover:bg-gray-300"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6.75 15.75 3 12m0 0 3.75-3.75M3 12h18"
                    />
                  </svg>
                </button>
                <div>
                  <h1 className="text-2xl font-bold">{session.class}</h1>
                  <p>
                    {sessionDate ? sessionDate : today}, {session.start_at} -{" "}
                    {session.end_at}
                  </p>
                </div>
              </div>
            </div>
            {/* <!-- Sticky Section --> */}
            <div className="sticky top-0 z-10 bg-white border border-t-0 border-gray-200 rounded-bl-xl rounded-br-xl col-span-full">
              <div className="flex justify-between">
                {/* <!--Count Present--> */}
                <div className="w-1/2 text-center p-4 border-r border-gray-200">
                  <h1 className="text-3xl font-bold">
                    {attendanceState.presentCount}
                  </h1>
                  <p>Present</p>
                </div>
                {/* <!--Count Absent--> */}
                <div className="w-1/2 text-center p-4">
                  <h1 className="text-3xl font-bold">
                    {attendanceState.absentCount}
                  </h1>
                  <p>Absent</p>
                </div>
              </div>
            </div>
            <div className="mt-5 col-span-full">
              {alert.show && (
                <Alert
                  show={alert.show}
                  content={alert.msg}
                  type={alert.type}
                ></Alert>
              )}
            </div>
            <form onSubmit={handleSubmit} className="col-span-full mt-5">
              <div>
                <span className="font-medium text-gray-700">Status: </span>{" "}
                {registerState === "saved" ? (
                  <span className="inline-flex items-center rounded-md bg-green-300 px-2 py-1 text-s font-medium text-gray-700 ring-1 ring-inset ring-green-600/20">
                    {registerState}
                  </span>
                ) : (
                  <span className="inline-flex items-center rounded-md bg-yellow-50 px-2 py-1 text-s font-medium text-yellow-800 ring-1 ring-inset ring-yellow-600/20">
                    {registerState}
                  </span>
                )}
              </div>

              <div className="overflow-hidden flex justify-between items-center py-2">
                <div>
                  <label
                    htmlFor="createdFrom"
                    className="block text font-medium text-gray-700"
                  >
                    Session Date:
                  </label>
                  <input
                    // required
                    type="date"
                    id="day"
                    name="day"
                    defaultValue={
                      sessionDate ? sessionDate : dayjs().format("DD/MM/YYYY")
                    }
                    min=""
                    // min={maxDate}
                    max={maxDate}
                    onChange={handleDateSelect}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="DD/MM/YYY"
                  />
                </div>

                <div className="">
                  <input
                    type="text"
                    x-model="search"
                    placeholder="Search student."
                    className="px-4 py-2 border-3 border-gray-500 rounded-md "
                    onChange={(e) => setSessionDate(e.target.value)}
                  />
                </div>
              </div>

              {
                <div className="">
                  <div className="mt-4 bg-white p-4 shadow sm:rounded-md">
                    {/* <div className="overflow-hidden flex justify-between items-center py-2">
                        <h1 className="text-2xl mb-5  font-bold">
                          Marked ({attendanceState.marked.length})
                        </h1>
                      </div> */}
                    <ul className="divide-y divide-solid divide-gray-200">
                      {students
                        .filter((item) => {
                          return searchParam.toLowerCase() === ""
                            ? item
                            : item.name.toLowerCase().includes(searchParam) ||
                                item.msisdn.includes(searchParam) ||
                                item.registration_no
                                  .toLowerCase()
                                  .includes(searchParam);
                        })
                        .map((student, index) => (
                          <li
                            className="overflow-hidden flex justify-between items-center py-2"
                            key={index}
                          >
                            <div>
                              <h4 className="block text font-bold text-gray-700">
                                {student.name}
                              </h4>
                              <p className="block text-sm font-medium text-gray-700">
                                {student.registration_no}
                              </p>
                              <p className="block text-sm font-medium text-gray-700">
                                {student.msisdn}
                              </p>
                            </div>
                            <div
                              x-data="{ activeState: entry.status, scrollY: 0 }"
                              className="space-x-4 flex justify-end"
                            >
                              {/* <!-- Present Button --> */}
                              <button
                                className="text-sm"
                                type="button"
                                disabled={
                                  attendanceState.registerState === "saved"
                                }
                              >
                                {attendanceState.present.includes(
                                  student.id
                                ) ? (
                                  <svg
                                    type="disabled"
                                    className="h-10 w-10"
                                    viewBox="0 0 32 32"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <rect
                                      width="32"
                                      height="32"
                                      rx="16"
                                      fill="#7EE86C"
                                    />
                                    <path
                                      d="M24 10.5L13 21.5L8 16.5"
                                      stroke="black"
                                      strokeWidth="2.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                ) : (
                                  <svg
                                    // type="disabled"
                                    className="h-10 w-10"
                                    viewBox="0 0 32 32"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    // onClick={() => {
                                    //   update(
                                    //     attendanceState.markedStates[index].id,
                                    //     "present"
                                    //   );
                                    // }}
                                    onClick={() =>
                                      handleMarkAttendance(
                                        student.id,
                                        "present"
                                      )
                                    }
                                  >
                                    <rect
                                      width="32"
                                      height="32"
                                      rx="16"
                                      fill="#D7D7D7"
                                    />
                                    <path
                                      d="M24 10.5L13 21.5L8 16.5"
                                      stroke="#8D8D8D"
                                      strokeWidth="2.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                )}
                              </button>
                              {/* <!-- Absent Button --> */}
                              <button
                                className="text-sm"
                                type="button"
                                disabled={registerState === "saved"}
                              >
                                {attendanceState.absent.includes(student.id) ? (
                                  <svg
                                    type="disabled"
                                    className="h-10 w-10"
                                    viewBox="0 0 32 32"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <rect
                                      width="32"
                                      height="32"
                                      rx="16"
                                      fill="#F61616"
                                    />
                                    <path
                                      d="M22 10L10 22"
                                      stroke="black"
                                      strokeWidth="2.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                    <path
                                      d="M10 10L22 22"
                                      stroke="black"
                                      strokeWidth="2.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                ) : (
                                  <svg
                                    type="disabled"
                                    className="h-10 w-10"
                                    viewBox="0 0 32 32"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    // onClick={() => {
                                    //   update(
                                    //     attendanceState.markedStates[index].id,
                                    //     "absent"
                                    //   );
                                    // }}
                                    onClick={() =>
                                      handleMarkAttendance(student.id, "absent")
                                    }
                                  >
                                    <rect
                                      width="32"
                                      height="32"
                                      rx="16"
                                      fill="#D7D7D7"
                                    />
                                    <path
                                      d="M22 10L10 22"
                                      stroke="#8D8D8D"
                                      strokeWidth="2.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                    <path
                                      d="M10 10L22 22"
                                      stroke="#8D8D8D"
                                      strokeWidth="2.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                )}
                              </button>
                            </div>
                          </li>
                        ))}
                    </ul>
                  </div>
                </div>
              }

              <div className="fixed bottom-4 left-0 w-full space-x-4 flex justify-center">
                <button
                  type="submit"
                  value="save"
                  className="rounded-md bg-blue-600 px-4 py-2 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600  disabled:bg-gray-500"
                  disabled={
                    attendanceState.absentCount + attendanceState.presentCount <
                      session?.students.length || registerState === "saved"
                  }
                >
                  Save and Submit
                </button>
                <button
                  type="button"
                  onClick={(e) => handleSaveDraft(e)}
                  className="px-4 py-2 bg-indigo-600 text-white rounded-md shadow-lg hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-opacity-75 disabled:bg-gray-400"
                  disabled={registerState === "saved"}
                >
                  Save as Draft
                </button>
              </div>
            </form>
          </div>
        )}
      </section>
    </>
  );
};
