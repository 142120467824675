import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Apis from "../../utils/api/api";
import { loadItem, saveItem } from "../../utils/helpers/localStorage";

export const fetchCourses = createAsyncThunk(
  "/registrations/fetchCourses",
  async () => {
    // const courses = loadItem("courses");
    // if (courses && courses.length !== 0) {
    //   // console.log("courses", courses)
    //   return courses;
    // }else{
      const { data: response } = await Apis.getCourses();
      // console.log("APi data", response.msg);
      if (response.data) {
        return response.data;
      } else {
        return [];
      }
    // }
  }
);

export const fetchCourse = createAsyncThunk(
  "/courses/fetchCourse",
  async (param) => {
    const { data: response } = await Apis.getCourse(param);
    // console.log(response.data);
    if (response.data) {
      return response.data;
    } else {
      return [];
    }
  }
);

export const addCourseAsync = createAsyncThunk(
  "courses/addCourse",
  async (course) => {
    try {
      const { data: response } = await Apis.addCourse(course);
      //   console.log("res success", response);
      if (!response.success) {
        return { data: null, msg: response.msg, status: 400 };
      }
      return { data: response.data, msg: response.msg, status: 200 };
    } catch (error) {
      const { data: response } = error.response;
      const { status } = error;
      console.log("Error:", status);
      return { data: null, msg: response.msg, status: status };
    }
  }
);

// export const selectCourseById = (state, courseId) => {
//   return state.courses.coursesList.find((course) => course.id === courseId);
// }

const initialState = {
  status: "idle",
  coursesList: [],
  error: null,
  courseItem: {},
};

const courseSlice = createSlice({
  name: "courses",
  initialState,
  reducers: {
    addCourse: (state, action) => {
      const courses = state.coursesList;
      const newCourses = [...courses, action.payload];
      return newCourses;
    },
    setEnrollCourse: (state, action) => {
      return { ...state, enrollCourse: action.payload };
    },
    getCourseById: (state, action) => {
      return state.coursesList.find((course) => course.id === action.payload);
    },
  },
  selectors: {
    selectCourseStatus: (state) => state.status,
    selectCourseError: (state) => state.error,
    selectCourses: (state) => state.coursesList,
    selectCourseById: (state, course_id) =>
      state.coursesList.find((course) => course.id == course_id),
  },

  extraReducers(builder) {
    builder
      .addCase(addCourseAsync.fulfilled, (state, action) => {
        const { data, msg, status } = action.payload;
        state.error = msg;
        if (status !== 200) {
          state.status = "failed";
        } else {
          state.status = "success";
          state.coursesList.push(data);
        }
      })
      .addCase(addCourseAsync.pending, (state, action) => {
        state.status = "pending";
        // state.coursesList.push(action.payload);
      })
      .addCase(addCourseAsync.rejected, (state, action) => {
        state.status = "failed";
      })
      .addCase(fetchCourse.fulfilled, (state, action) => {
        state.status = "success";
        state.courseItem = action.payload;
      })
      .addCase(fetchCourse.pending, (state, action) => {
        state.status = "pending";
      })
      .addCase(fetchCourse.rejected, (state, action) => {
        state.status = "failed";
      })
      .addCase(fetchCourses.fulfilled, (state, action) => {
        // console.log("action.payload", action.payload);
        state.status = "success";
        state.coursesList = action.payload;
        // saveItem("courses", action.payload);
      })
      .addCase(fetchCourses.pending, (state, action) => {
        state.status = "pending";
      })
      .addCase(fetchCourses.rejected, (state, action) => {
        state.status = "failed";
      });
  },
});

export const { addCourse, setEnrollCourse, getCourseById } =
  courseSlice.actions;
export const {
  selectCourseStatus,
  selectCourseError,
  selectCourses,
  selectCourseById,
} = courseSlice.selectors;

export default courseSlice.reducer;
