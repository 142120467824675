import React, { useState } from "react";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Apis from "../../utils/api/api";

const ExportEnrollmentsCSV = ({ show, handleClose }) => {
  const [createdFrom, setCreatedFrom] = useState();
  const [createdTo, setCreatedTo] = useState();
  const [exporting, setExporting] = useState(false);

  // const dispatch = useDispatch();

  // const data = useSelector(selectEnrollments);



  const handleExportCSV = async () => {
    setExporting(true);
    try {
      const response = await Apis.exportRegistrations({
        params: { createdFrom, createdTo },
        responseType: "blob", // We expect binary data (CSV file) as a response
      });

      // Create a URL for the blob object and download the file
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      const fileName = `Registrations_${Math.floor(Date.now() / 1000)}.csv`;
      link.setAttribute("download", fileName); // CSV filename
      document.body.appendChild(link);
      link.click();
      link.remove();
      setCreatedFrom("");
      setCreatedTo("");
    } catch (error) {
      setExporting(false);
      console.error("Error exporting data:", error);
      alert("An error occurred while exporting data.");
    }finally{
      setExporting(false);
    }
  };




  // const handleExportCSV = async () => {
  //   // Map the person data to CSV format
  //   const csvData = data.map((student) => ({
  //     "Full Name": student.full_name,
  //     "Phone Number": student.msisdn,
  //     "Registration Number": student.registration_no,
  //     Course: student.class.course,
  //     Class: student.class.class,
  //     "Enrollment Type": student.enrollment_type,
  //     "Enrollment Status": student.status,
  //     Residence: student.residence,
  //     "Registration Date": student.created_at,
  //   }));

  //   // Use PapaParse to convert JSON data to CSV
  //   const csv = Papa.unparse(csvData);

  //   const fileName = `registrations_${Math.floor(Date.now() / 1000)}`;

  //   // Create a Blob with the CSV content and specify the file type
  //   const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

  //   // Use FileSaver to save the file to the user's Downloads folder
  //   saveAs(blob, `${fileName}.csv`);
  //   setCreatedFrom("");
  //   setCreatedTo("");
  // };
  // useEffect(() => {
  //   dispatch(
  //     fetchEnrollments({
  //       createdFrom: createdFrom,
  //       createdTo: createdTo,
  //     })
  //   );
  // }, [createdFrom, createdTo, dispatch]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      {show && (
        <div
          className=" relative z-10"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div
            className="fixed inset-0 bg-gray-500 bg-opacity-15 transition-opacity"
            aria-hidden="true"
          ></div>

          <div className="fixed inset-0 z-1 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <div className="relative sm:max-w-full lg:size-1/4 transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all ">
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                      <h1 className="text-lg font-normal text-gray-900">
                        Select Dates to Export
                      </h1>
                    </div>
                    <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                      <button
                        type="button"
                        className="justify-center p-1 text-sm font-normal text-red-500 border border-red-300 rounded-lg ring-gray-300 sm:mt-0 sm:w-auto"
                        onClick={handleClose}
                      >
                        {/* <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          className="size-6 rounded-md bg-red-500 hover:bg-red-400"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                          />
                        </svg> */}
                        Close
                      </button>
                    </div>
                  </div>
                  <hr className="h-px my-3 bg-gray-200 border-0 dark:bg-gray-700"></hr>
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto max-w-full">
                      <div className="col-span-full">
                        <div className="grid grid-cols-2 ">
                          <div className="flex flex-row p-4">
                            <label
                              htmlFor="createdFrom"
                              className="block text font-medium text-gray-700"
                            >
                              Date From:
                            </label>
                            <div className="mt-1 ml-2">
                              <input
                                required
                                type="date"
                                id="createdFrom"
                                name="createdFrom"
                                // value={Tday}
                                defaultValue={createdFrom}
                                min="01/01/2024"
                                max="31/12/2024"
                                onChange={(e) => setCreatedFrom(e.target.value)}
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholder="DD/MM/YYY"
                              />

                              {/* <DateField
                                label="Date From"
                                onChange={(newValue) =>
                                  setCreatedFrom(newValue)
                                }
                                name="createdFrom"
                                // value={createdFrom}
                                format="DD/MM/YYYY"
                                required
                              /> */}
                            </div>
                          </div>
                          <div className="flex flex-row p-4">
                            <label
                              htmlFor="createdFrom"
                              className="block text font-medium text-gray-700"
                            >
                              Date To:
                            </label>
                            <div className="mt-1 ml-2">
                              <input
                                required
                                type="date"
                                id="createdTo"
                                name="createdTo"
                                // value={Tday}
                                defaultValue={createdTo}
                                min="01/01/2024"
                                max="31/12/2024"
                                onChange={(e) => setCreatedTo(e.target.value)}
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholder="DD/MM/YYY"
                              />
                              {/* <DateField
                                label="Date To"
                                onChange={(newValue) =>
                                  setCreatedTo(newValue.date())
                                }
                                name="createdTo"
                                // value={createdTo}
                                format="DD/MM/YYYY"
                                required
                              /> */}
                            </div>
                          </div>
                        </div>
                        <div>
                          {exporting ? (
                            <button
                              type="button"
                              className="rounded-md border border-transparent bg-green-300 px-4 py-2 text-sm font-medium text-gray-900 shadow-sm"
                              disabled
                            >
                              {/* <svg
                                className="animate-spin h-5 w-5 mr-3 ..."
                                viewBox="0 0 24 24"
                              ></svg> */}
                              Exporting...
                            </button>
                          ) : (
                            <button
                              disabled={!createdFrom || !createdTo}
                              type="submit"
                              className="mt-6 rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:bg-gray-500"
                              onClick={handleExportCSV}
                            >
                              Export
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </LocalizationProvider>
  );
};

export default ExportEnrollmentsCSV;
